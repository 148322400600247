import { useCallback, useMemo } from 'react';

import { EmbeddedTypeform, TypeformSubmitEvent } from '@/components/EmbeddedTypeform/EmbeddedTypeform';
import { toast } from '@/components/RMToast/RMToast';
import { getQueryParamsObject, useBooleanQueryParam } from '@/hooks/useQueryParam';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';
import { getMarketingCookies } from '@/utils/marketing-cookies';

function InternalCheckoutGiftPage() {
  const { checkoutService } = useServices();

  const handleSubmit = useCallback(
    async (e: TypeformSubmitEvent) => {
      try {
        const marketingCookies = getMarketingCookies();
        const checkout = await checkoutService.giftCheckout({
          ...marketingCookies,
          typeformFormId: e.formId,
          typeformResponseId: e.responseId,
          cancelQueryParams: getQueryParamsObject(),
          successQueryParams: getQueryParamsObject(),
        });
        window.open(checkout.paymentUrl, '_self');
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    },
    [checkoutService],
  );

  const [skipProjectType] = useBooleanQueryParam('skip-project-type', false);
  const hiddenFields = useMemo(() => {
    // Typeform does not support dashes
    return {
      skip_project_type: String(skipProjectType),
    };
  }, [skipProjectType]);

  return (
    <EmbeddedTypeform
      formId={import.meta.env.VITE_GIFT_FORM_ID}
      fullScreen
      hiddenFields={hiddenFields}
      onSubmit={handleSubmit}
    />
  );
}

export function CheckoutGiftPage() {
  return (
    <RementoPage type="empty">
      <InternalCheckoutGiftPage />
    </RementoPage>
  );
}
