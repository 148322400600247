import { faCheck, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import RatingStarsSrc from '@/assets/rating-start-5.svg';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';

import {
  Banner,
  BannerWrapper,
  DetailsCard,
  DetailsRoot,
  FeaturesList,
  FeaturesListItem,
  FeaturesListItemIcon,
  Header,
  NoteCard,
  PriceWrapper,
  ReviewCard,
  ReviewStars,
  Timeline,
  TimelineHeader,
  TimelineHeaderTextWrapper,
  TimelineIcon,
  TimelineIconWrapper,
  TimelineLine,
  TimelineSection,
  TimelineStepList,
  TimelineStepListItem,
  TimelineStepListItemIcon,
  TimelineWrapper,
  TitleWrapper,
} from './ExperimentalProjectCheckoutAudienceDetails.styles.js';

export interface Banner {
  src: string;
  maxWidth?: string;
}

export interface TestimonialReview {
  title: string;
  message: string;
  author: string;
}

export interface TimelineConfig {
  title: string;
  sections: {
    id: string;
    title: string;
    subtitle: string;
    icon: IconDefinition;
    items: string[];
  }[];
}

export interface CheckoutAudienceDetailsRenderProps {
  banner?: Banner | null;
  title?: string | null;
  subtitle?: string | null;
  detail?: string | null;
  pricePrefix?: string | null;
  features?: string[] | null;
  timeline?: TimelineConfig | null;
  testimonialReview?: TestimonialReview | null;
  note?: string | null;
}

export function ExperimentalProjectCheckoutAudienceDetails({
  banner,
  title,
  subtitle,
  detail,
  pricePrefix,
  features,
  timeline,
  testimonialReview,
  note,
}: CheckoutAudienceDetailsRenderProps) {
  const isMobile = useIsMobileViewport();

  return (
    <DetailsRoot role="region" aria-labelledby="checkout-audience-details-title">
      <DetailsCard>
        {banner != null && (
          <BannerWrapper>
            <Banner src={banner.src} alt="Banner image" aria-hidden style={{ maxWidth: banner.maxWidth }} />
          </BannerWrapper>
        )}
        {title != null && (
          <Header>
            <TitleWrapper>
              <RMText
                type="serif"
                size={isMobile ? 'm' : 'l'}
                color="on-surface-primary"
                as="h2"
                id="checkout-audience-details-title"
              >
                {title}
              </RMText>
              <PriceWrapper>
                {pricePrefix != null && (
                  <RMText type="sans" size="xxs" color="on-surface-tertiary">
                    {pricePrefix}
                  </RMText>
                )}
                <RMText type="serif" size="m" color="on-surface-primary" bold>
                  $99
                </RMText>
              </PriceWrapper>
            </TitleWrapper>
            <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-secondary">
              {subtitle}
            </RMText>
            {detail != null && (
              <RMText type="sans" size="xs" color="on-surface-secondary">
                {detail}
              </RMText>
            )}
          </Header>
        )}

        {features != null && (
          <FeaturesList>
            {features.map((f) => (
              <FeaturesListItem key={f}>
                <FeaturesListItemIcon icon={faCheck} color="var(--on-surface-secondary)" />
                <RMText type="sans" size="xs" color="on-surface-secondary">
                  {f}
                </RMText>
              </FeaturesListItem>
            ))}
          </FeaturesList>
        )}

        {timeline != null && (
          <TimelineWrapper role="complementary" aria-labelledby="checkout-audience-details-timeline-title">
            <RMText
              type="serif"
              size={title != null ? 'l' : 'xl'}
              color="on-surface-primary"
              as="h3"
              id="checkout-audience-details-timeline-title"
            >
              {timeline.title}
            </RMText>
            <Timeline>
              {timeline.sections.map((section, sectionIndex) => (
                <TimelineSection key={section.title}>
                  <TimelineLine data-last={sectionIndex === timeline.sections.length - 1} />
                  <TimelineHeader>
                    <TimelineIconWrapper>
                      <TimelineIcon icon={section.icon} />
                    </TimelineIconWrapper>
                    <TimelineHeaderTextWrapper>
                      <RMText
                        type="serif"
                        size="m"
                        color="on-surface-primary"
                        id={`checkout-audience-details-timeline-${section.id}-title`}
                      >
                        {section.title}
                      </RMText>
                      <RMText
                        type="sans"
                        size="xxs"
                        color="on-surface-tertiary"
                        italic
                        id={`checkout-audience-details-timeline-${section.id}-description`}
                      >
                        {section.subtitle}
                      </RMText>
                    </TimelineHeaderTextWrapper>
                  </TimelineHeader>
                  <TimelineStepList
                    aria-labelledby={`checkout-audience-details-timeline-${section.id}-title`}
                    aria-describedby={`checkout-audience-details-timeline-${section.id}-description`}
                  >
                    {section.items.map((item) => (
                      <TimelineStepListItem key={item}>
                        <TimelineStepListItemIcon />
                        <RMText type="sans" size="xs" color="on-surface-primary">
                          {item}
                        </RMText>
                      </TimelineStepListItem>
                    ))}
                  </TimelineStepList>
                </TimelineSection>
              ))}
            </Timeline>
          </TimelineWrapper>
        )}

        {testimonialReview != null && (
          <ReviewCard role="complementary" aria-labelledby="checkout-audience-details-review-title">
            <ReviewStars src={RatingStarsSrc} alt="5 out of 5 stars" aria-label="Rated 5 out of 5 stars" />
            <RMText
              type="sans"
              size="s"
              color="on-surface-primary"
              bold
              as="h3"
              id="checkout-audience-details-review-title"
            >
              {testimonialReview.title}
            </RMText>
            <RMText
              type="sans"
              size={isMobile ? 'xxs' : 's'}
              color="on-surface-primary"
              as="blockquote"
              aria-label="Customer review message"
            >
              {testimonialReview.message}
            </RMText>
            <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-primary" bold aria-label="Review by">
              {testimonialReview.author}
            </RMText>
          </ReviewCard>
        )}
      </DetailsCard>

      {note != null && (
        <NoteCard>
          <RMText type="sans" size="xs" color="on-surface-primary">
            {note}
          </RMText>
        </NoteCard>
      )}
    </DetailsRoot>
  );
}
