import { styled } from '@linaria/react';

export const SummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  width: 100%;
  background-color: #eff3f2;
  border-radius: var(--radius-smooth);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-md);
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CartItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) 0;
`;

export const CartItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const CartItemNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CartItemDescriptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const CartItemDescriptionListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2xs);
`;

export const CartDivider = styled.div`
  height: 1px;
  flex: 0 0 1px;
  background-color: var(--border-weak);
`;

export const PreviewImage = styled.img`
  max-height: 48px;
  max-width: 48pc;
  object-fit: cover;
  border-radius: 4px;
`;
