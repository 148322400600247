import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { CheckboxContent, Content, Message } from './ProjectCheckoutEbookCheckbox.styles.js';

interface ProjectCheckoutEbookCheckboxProps {
  onChange: (value: boolean) => void;
  value: boolean;
  experimentalStyling?: boolean;
}

export function ProjectCheckoutEbookCheckbox({
  onChange,
  value = false,
  experimentalStyling,
}: ProjectCheckoutEbookCheckboxProps) {
  return (
    <Content data-checked={value} data-experimental-styling={experimentalStyling} onClick={() => onChange(!value)}>
      <CheckboxContent>
        <RMCheckbox
          id="checkout-ebook"
          square
          size="sm"
          checked={value}
          aria-labelledby="checkout-ebook-label"
          aria-describedby="checkout-ebook-description"
        />
        <RMSpacer direction="row" spacing="xs" />
        <RMText id="checkout-ebook-label" as="label" type="sans" size="s" bold color="on-surface-primary">
          Add e-book
        </RMText>
        <RMSpacer direction="row" spacing="3xl" />
        <RMText type="sans" size="s" bold color="on-surface-primary">
          $24.99
        </RMText>
        <RMSpacer direction="row" spacing="sm" />
        <RMText
          style={{ textDecoration: 'line-through' }}
          type="sans"
          size="xs"
          color="on-surface-tertiary"
          aria-hidden="true"
        >
          $49.99{' '}
        </RMText>
      </CheckboxContent>
      <Message type="sans" size="xs" color="on-surface-primary" id="checkout-ebook-description">
        Expand the reach of your stories even further. Choose the e-book option to have your book available in digital
        formats (PDF, Kindle, Epub, etc.). Save 50% by purchasing in advance.
      </Message>
    </Content>
  );
}
