import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CartPreviewSrc from '@/assets/checkout-audience-myself.webp';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip.js';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';

import {
  CartDivider,
  CartItem,
  CartItemDescriptionList,
  CartItemDescriptionListItem,
  CartItemNameWrapper,
  CartItems,
  Header,
  HeaderTextWrapper,
  NoteCard,
  PreviewImage,
  SummaryCard,
  SummaryRoot,
} from './ExperimentalProjectCheckoutSummary.styles.js';
import { ExperimentalProjectCheckoutSummaryProps } from './ExperimentalProjectCheckoutSummary.types.js';

export function ExperimentalProjectCheckoutSummary({ total, items }: ExperimentalProjectCheckoutSummaryProps) {
  const isMobile = useIsMobileViewport();

  return (
    <SummaryRoot>
      <SummaryCard
        role="complementary"
        aria-labelledby={isMobile ? undefined : 'checkout-summary-title'}
        aria-label={isMobile ? 'Your cart' : undefined}
      >
        {!isMobile && (
          <RMText type="serif" size="xl" color="on-surface-primary" id="checkout-summary-title" as="h2">
            Your cart
          </RMText>
        )}
        <Header>
          <PreviewImage src={CartPreviewSrc} alt="Cart preview" aria-hidden />
          <HeaderTextWrapper>
            <RMText
              type={isMobile ? 'sans' : 'serif'}
              size={isMobile ? 'xs' : 'm'}
              color="on-surface-primary"
              bold={isMobile}
              id="checkout-summary-book-title"
            >
              The Remento Book
            </RMText>
            <ul aria-labelledby="checkout-summary-book-title">
              <li>
                <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-secondary">
                  Unlimited prompts & recordings
                </RMText>
              </li>
              <li>
                <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-secondary">
                  Unlimited collaborators
                </RMText>
              </li>
              <li>
                <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-secondary">
                  Secure storage
                </RMText>
              </li>
            </ul>
          </HeaderTextWrapper>
        </Header>
        <CartItems role="list" aria-label="Cart items">
          <CartDivider />
          {items.map((item) => (
            <CartItem key={item.name} role="listitem">
              <CartItemNameWrapper>
                <RMText type="sans" size={isMobile ? 'xxs' : 's'} color="on-surface-primary" bold>
                  {item.name}
                </RMText>
                <RMText
                  type="sans"
                  size={isMobile ? 'xxs' : 's'}
                  color={item.price >= 0 ? 'on-surface-primary' : 'affirmative'}
                  bold
                >
                  {item.price > 0 && '$' + item.price.toFixed(2)}
                  {item.price < 0 && '-$' + Math.abs(item.price).toFixed(2)}
                  {item.price === 0 && 'FREE'}
                </RMText>
              </CartItemNameWrapper>
              {item.description != null && item.description.length > 0 && (
                <CartItemDescriptionList>
                  {item.description.map((d) => (
                    <CartItemDescriptionListItem key={d.text}>
                      <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-secondary">
                        {d.text}
                      </RMText>
                      {d.tooltip != null && (
                        <RMTooltip showOnTouch message={d.tooltip} align="center" side="top">
                          <FontAwesomeIcon icon={faCircleExclamation} size="sm" color="var(--on-secondary)" />
                        </RMTooltip>
                      )}
                    </CartItemDescriptionListItem>
                  ))}
                </CartItemDescriptionList>
              )}
            </CartItem>
          ))}
          <CartDivider />
          <CartItemNameWrapper role="listitem">
            <RMText type="sans" size={isMobile ? 'xxs' : 's'} color="on-surface-primary" bold>
              Total due today
            </RMText>
            <RMText
              type="sans"
              size={isMobile ? 'xxs' : 's'}
              color={total != null ? 'on-surface-primary' : 'on-surface-tertiary'}
              bold={total != null}
            >
              {total != null ? '$' + total.toFixed(2) : 'Loading...'}
            </RMText>
          </CartItemNameWrapper>
        </CartItems>
      </SummaryCard>

      {!isMobile && (
        <NoteCard role="region" aria-labelledby="checkout-summary-why-subscription">
          <RMText type="sans" size="xs" color="on-surface-primary" bold="medium" id="checkout-summary-why-subscription">
            Why is Remento a subscription?
          </RMText>
          <RMText type="sans" size="xs" color="on-surface-tertiary">
            Remento is a subscription so that every person can work at their own pace. You only need an active
            subscription while recording—once your book is complete, simply cancel in just a few clicks. You'll still
            have lifetime access to all your recordings even after cancellation.{' '}
            <a href="https://help.remento.co/en/articles/10925084-why-is-remento-a-subscription" target="_blank">
              <RMText type="sans" size="xs" color="on-surface-tertiary" underline>
                Learn more
              </RMText>
            </a>
            .
          </RMText>
        </NoteCard>
      )}
    </SummaryRoot>
  );
}
