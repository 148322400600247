import { BookExperiments } from '@remento/book-ab-testing';
import { UserCoupon, UserCouponType } from '@remento/types/user';
import Cookies from 'js-cookie';

import { getQueryParam } from '@/hooks/useQueryParam';
import { useServices } from '@/Services';
import { useUserRuid } from '@/services/analytics/analytics.hooks';

import { ProjectCheckoutCoupon, ProjectType } from './project-checkout.manager.js';

export function getProjectCheckoutCoupons(userCoupons: UserCoupon[] | null | undefined): ProjectCheckoutCoupon[] {
  const coupons: ProjectCheckoutCoupon[] = [];

  const userCoupon = userCoupons?.find((c) => c.type === UserCouponType.BUNDLE)?.value;
  if (userCoupon != null) {
    coupons.push({ origin: 'user', coupon: userCoupon });
  }

  const queryParamCoupon = getQueryParam('coupon');
  if (queryParamCoupon != null) {
    coupons.push({ origin: 'query-param', queryParamName: 'coupon', coupon: queryParamCoupon });
  }

  const cookieCoupon = Cookies.get('referral-coupon');
  if (cookieCoupon != null) {
    coupons.push({ origin: 'cookie', cookieName: 'referral-coupon', coupon: cookieCoupon });
  }

  return coupons;
}

export function useIsExperimentalProjectCheckoutLayoutEnabled(projectType: ProjectType): boolean {
  const { abTestService } = useServices();
  const ruid = useUserRuid();
  const checkoutLayoutVariant = abTestService.getUserVariant(
    ruid,
    BookExperiments.PROJECT_CHECKOUT_LAYOUT,
    navigator.userAgent,
  );
  return checkoutLayoutVariant === 'experimental' && projectType !== 'BABYBOOK' && projectType !== 'FREE';
}
