import { styled } from '@linaria/react';

export const Card = styled.aside`
  padding: var(--spacing-md);
  background-color: var(--surface-bright);
  border-radius: var(--radius-round);
  border: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-bottom-1);
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-md);
`;

export const CardContent = styled.div`
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
`;

export const Image = styled.img`
  height: 84px;
`;

export const Content = styled.div`
  width: 100%;
`;
