import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const Content = styled.div`
  border-radius: var(--radius-regular);
  border: 1px solid var(--on-surface-tertiary);
  padding: var(--spacing-md);
  cursor: pointer;

  :hover {
    border: 1px solid var(--border-stronger);
  }

  &[data-checked='true'][data-experimental-styling='true'] {
    border: 2px solid var(--primary);
    padding: calc(var(--spacing-md) - 1px);
    background-color: var(--surface-bright);
  }
`;

export const CheckboxContent = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;
`;

export const Message = styled(RMText)`
  display: block;
  padding: var(--spacing-md) 1.375rem 0 1.375rem;
`;
