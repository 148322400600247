import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledProjectCheckoutDesktopLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: var(--surface-brightest);
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const Header = styled.header`
  min-height: 5rem;
  padding: 0 var(--spacing-3xl);
  width: 100%;
  max-width: 85rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wordmark = styled(RMWordmark)`
  height: 1.5rem;
`;

export const Main = styled.main`
  display: flex;
  width: 100%;
  height: calc(100% - 5rem);
  overflow: auto;
  position: relative;
`;

export const MainContent = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  min-height: 100%;
  gap: var(--spacing-3xl);
`;

export const Left = styled.section`
  display: flex;
  align-items: self-end;
  flex-flow: column;
  padding: var(--spacing-3xl) 0 var(--spacing-3xl) var(--spacing-3xl);
  width: 100%;
`;

export const LeftForm = styled.div`
  width: 100%;
  height: 100%;
  max-width: 38.5rem;
`;

export const LeftFormButton = styled.div`
  width: 100%;
  max-width: 250px;
`;

export const Right = styled.aside`
  width: 100%;
  padding: var(--spacing-5xl) var(--spacing-4xl) var(--spacing-2xl);
`;

export const StepperWrapper = styled.div`
  padding-right: var(--spacing-lg);
`;
