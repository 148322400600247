import { createLogger } from '@remento/logger';
import { ShippingAddress } from '@remento/types/shipping';

import { getQueryParamsObject } from '@/hooks/useQueryParam.js';
import { CheckoutRequest } from '@/services/api/checkout';
import { getMarketingCookies } from '@/utils/marketing-cookies';

import { ShippingAddressValidationDialogContainer } from '../book-builder/containers/ShippingAddressValidationDialog.container.js';
import { shouldShowShippingValidationDialog } from '../book-builder/utils/shipping.utils.js';
import { openDialog } from '../dialog/dialog-manager.js';
import { getInputValue, getInputValueOrThrow, setInputValue } from '../form/input.js';
import { RoutePath } from '../routing/types/routing.types.js';

import {
  getCheckoutData,
  ProjectCheckoutManager,
  ProjectCheckoutStep,
  ProjectType,
} from './project-checkout.manager.js';

const logger = createLogger('project-checkout-steps');

async function identifyCheckoutOwner(manager: ProjectCheckoutManager): Promise<void> {
  const projectType = getInputValue(manager.form, 'type');
  const ownerEmail = getInputValue(manager.form, 'owner.email');
  const ownerFirstName = getInputValue(manager.form, 'owner.person.firstName');
  const ownerLastName = getInputValue(manager.form, 'owner.person.lastName');
  if (!projectType || !ownerEmail || !ownerFirstName || !ownerLastName) {
    throw new Error('Missing checkout contact form value');
  }
  await manager.services.browserAnalytics
    .identify({ type: 'email', value: ownerEmail }, 'book-checkout', {
      firstName: ownerFirstName,
      lastName: ownerLastName,
      email: ownerEmail,
      currentCheckoutUseCase:
        projectType === 'AUTOBIOGRAPHY' ? 'autobiography' : projectType === 'BIOGRAPHY' ? 'biography' : 'babybook',
      unsubscribed: false,
    })
    .catch((error) => logger.error('IDENTIFY_FAILED', () => ({ error })));
  manager.services.checkoutAnalyticsService.onCheckoutContactFormSubmitted();
}

async function handleCheckout(manager: ProjectCheckoutManager): Promise<void> {
  const checkoutData = await getCheckoutData(manager);
  const marketingCookies = getMarketingCookies();
  manager.services.checkoutAnalyticsService.onCheckoutStarted(marketingCookies);
  const checkout = await manager.services.checkoutService.checkout({
    cancelQueryParams: getQueryParamsObject(),
    successQueryParams: getQueryParamsObject(),
    ...checkoutData,
    ...marketingCookies,
  } as CheckoutRequest);
  window.open(checkout.paymentUrl, '_self');
}

async function validateAddons(manager: ProjectCheckoutManager): Promise<boolean> {
  const legacyboxQuantity = getInputValue(manager.form, 'addons.legacybox.quantity');
  if (legacyboxQuantity == null || legacyboxQuantity == 0) {
    return true;
  }

  // Retrieve the shipping address
  const recipientName = getInputValueOrThrow(manager.form, 'addons.legacybox.recipientName');
  const line1 = getInputValueOrThrow(manager.form, 'addons.legacybox.addressLine1');
  const line2 = getInputValue(manager.form, 'addons.legacybox.addressLine2');
  const city = getInputValueOrThrow(manager.form, 'addons.legacybox.city');
  const state = getInputValueOrThrow(manager.form, 'addons.legacybox.state');
  const zipCode = getInputValueOrThrow(manager.form, 'addons.legacybox.zipCode');
  const country = getInputValueOrThrow(manager.form, 'addons.legacybox.country');

  const shippingAddress: ShippingAddress = {
    recipientName,
    line1,
    line2,
    city,
    state,
    postalCode: zipCode,
    country,
  };

  // Validate the address
  const validationResult = await manager.services.bookService.validateAddress(shippingAddress);
  if (!shouldShowShippingValidationDialog(shippingAddress, validationResult)) {
    return true;
  }

  // Show the validation dialog
  let valid = false;
  await openDialog((onClose) => {
    return (
      <ShippingAddressValidationDialogContainer
        input={shippingAddress}
        validationResult={validationResult}
        supportMessageSuffix="I have received the following error message as I attempted to place my Legacybox order."
        onSave={(address) => {
          valid = true;
          setInputValue(manager.form, 'addons.legacybox.addressLine1', address.line1);
          setInputValue(manager.form, 'addons.legacybox.addressLine2', address.line2);
          setInputValue(manager.form, 'addons.legacybox.city', address.city);
          setInputValue(manager.form, 'addons.legacybox.state', address.state);
          setInputValue(manager.form, 'addons.legacybox.zipCode', address.postalCode);
          onClose();
        }}
        onClose={onClose}
      />
    );
  });
  return valid;
}

export const BIOGRAPHY_PROJECT_CHECKOUT_STEPS: ProjectCheckoutStep[] = [
  {
    name: 'audience',
    label: 'Storyteller',
    route: RoutePath.CheckoutBiographyAudience,
    inputs: [],
  },
  {
    name: 'delivery',
    label: 'Delivery',
    route: RoutePath.CheckoutBiographyDelivery,
    inputs: [
      'recipient.email',
      'recipient.phone',
      'recipient.person.firstName',
      'recipient.person.lastName',
      'gift.from',
      'gift.message',
      'gift.sendOn',
    ],
    postStepCallback: async (manager) => {
      const recipientFirstName = getInputValue(manager.form, 'recipient.person.firstName');
      const recipientLastName = getInputValue(manager.form, 'recipient.person.lastName');
      if (!recipientFirstName || !recipientLastName) {
        throw new Error('Missing checkout storyteller form value');
      }
      manager.services.checkoutAnalyticsService.onCheckoutStorytellerFormSubmitted(
        recipientFirstName,
        recipientLastName,
      );
    },
  },
  {
    name: 'contact',
    label: 'Details',
    route: RoutePath.CheckoutBiographyContact,
    inputs: ['owner.email', 'owner.person.firstName', 'owner.person.lastName', 'owner.phone'],
    postStepCallback: identifyCheckoutOwner,
  },
  {
    name: 'addons',
    label: 'Add-ons',
    route: RoutePath.CheckoutBiographyAddons,
    inputs: [
      'addons.books',
      'addons.ebook',
      'addons.legacybox.quantity',
      'addons.legacybox.shipDate',
      'addons.legacybox.recipientName',
      'addons.legacybox.addressLine1',
      'addons.legacybox.city',
      'addons.legacybox.state',
      'addons.legacybox.zipCode',
      'addons.legacybox.country',
    ],
    postStepCallback: validateAddons,
  },
  {
    name: 'review',
    label: 'Review',
    route: RoutePath.CheckoutBiographyReview,
    inputs: [],
    postStepCallback: handleCheckout,
  },
];

export const AUTOBIOGRAPHY_PROJECT_CHECKOUT_STEPS: ProjectCheckoutStep[] = [
  {
    name: 'audience',
    label: 'Storyteller',
    route: RoutePath.CheckoutBiographyAudience,
    inputs: [],
  },
  {
    name: 'contact',
    label: 'Details',
    route: RoutePath.CheckoutAutobiographyContact,
    inputs: ['owner.email', 'owner.person.firstName', 'owner.person.lastName', 'owner.phone'],
    postStepCallback: identifyCheckoutOwner,
  },
  {
    name: 'addons',
    label: 'Add-ons',
    route: RoutePath.CheckoutAutobiographyAddons,
    inputs: [
      'addons.books',
      'addons.ebook',
      'addons.legacybox.quantity',
      'addons.legacybox.shipDate',
      'addons.legacybox.recipientName',
      'addons.legacybox.addressLine1',
      'addons.legacybox.city',
      'addons.legacybox.state',
      'addons.legacybox.zipCode',
      'addons.legacybox.country',
    ],
    postStepCallback: validateAddons,
  },
  {
    name: 'review',
    label: 'Review',
    route: RoutePath.CheckoutAutobiographyReview,
    inputs: [],
    postStepCallback: handleCheckout,
  },
];

export const BABYBOOK_PROJECT_CHECKOUT_STEPS: ProjectCheckoutStep[] = [
  {
    name: 'audience',
    label: 'Project type',
    route: RoutePath.CheckoutBabyAudience,
    inputs: [],
  },
  {
    name: 'contact',
    label: 'Your profile',
    route: RoutePath.CheckoutBabyHost,
    inputs: ['owner.email', 'owner.person.firstName', 'owner.person.lastName', 'owner.phone', 'subject.firstName'],
    postStepCallback: identifyCheckoutOwner,
  },
  {
    name: 'details',
    label: 'Book details',
    route: RoutePath.CheckoutBabyProject,
    inputs: ['name', 'addons.books', 'addons.ebook'],
  },
  {
    name: 'review',
    label: 'Review',
    route: RoutePath.CheckoutBabyReview,
    inputs: [],
    postStepCallback: handleCheckout,
  },
];

export const FREE_PROJECT_CHECKOUT_STEPS: ProjectCheckoutStep[] = [
  {
    name: 'details',
    label: 'Details',
    route: RoutePath.CheckoutFreeDetails,
    inputs: ['recipientPersonId', 'subscriptionOwnerPersonId', 'name', 'addons.books', 'addons.ebook'],
  },
  {
    name: 'review',
    label: 'Review',
    route: RoutePath.CheckoutFreeReview,
    inputs: [],
    postStepCallback: handleCheckout,
  },
];

export const PROJECT_CHECKOUT_STEPS: Record<ProjectType, ProjectCheckoutStep[]> = {
  BIOGRAPHY: BIOGRAPHY_PROJECT_CHECKOUT_STEPS,
  AUTOBIOGRAPHY: AUTOBIOGRAPHY_PROJECT_CHECKOUT_STEPS,
  BABYBOOK: BABYBOOK_PROJECT_CHECKOUT_STEPS,
  FREE: FREE_PROJECT_CHECKOUT_STEPS,
};
