import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { PickerWrapper, StyledGiftCardForm } from './ExperimentalProjectCheckoutGiftTypeForm.styles.js';

// eslint-disable-next-line react-refresh/only-export-components
export enum GiftType {
  GUIDED = 'guided',
  GIFT_ONLY = 'gift-only',
}

export interface ExperimentalProjectCheckoutGiftTypeFormProps {
  selectedValue: GiftType;
  onSelectedChange: (selected: GiftType) => void;
}

export function ExperimentalProjectCheckoutGiftTypeForm({
  selectedValue,
  onSelectedChange,
}: ExperimentalProjectCheckoutGiftTypeFormProps) {
  const isMobile = useIsMobileViewport();

  return (
    <StyledGiftCardForm>
      <RMText
        as="h1"
        type="serif"
        size={isMobile ? 'l' : 'xl'}
        color="on-surface-primary"
        style={{ alignSelf: 'start' }}
      >
        Choose your gift type
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <PickerWrapper>
        <RMPicker.Root>
          <RMPicker.Item
            label="Help guide their experience"
            description="You add their prompts, invite family, and get notified when they tell a new story"
            banner="Most popular"
            value={GiftType.GUIDED}
            selected={selectedValue === GiftType.GUIDED}
            onClick={() => onSelectedChange(GiftType.GUIDED)}
          />
          <RMPicker.Item
            label="Gift only"
            description="Your gift recipient will select their own prompts and choose who to invite"
            value={GiftType.GIFT_ONLY}
            selected={selectedValue === GiftType.GIFT_ONLY}
            onClick={() => onSelectedChange(GiftType.GIFT_ONLY)}
          />
        </RMPicker.Root>
      </PickerWrapper>
    </StyledGiftCardForm>
  );
}
