import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMStepper } from '@/components/RMStepper/RMStepper';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getQueryParam } from '@/hooks/useQueryParam';
import { setInputValues } from '@/modules/form/input';
import {
  AudienceTarget,
  ProjectCheckoutAudienceForm,
} from '@/modules/project-checkout/components/ProjectCheckoutAudienceForm/ProjectCheckoutAudienceForm';
import { ProjectCheckoutDesktopLayout } from '@/modules/project-checkout/components/ProjectCheckoutLayout/ProjectCheckoutDesktopLayout';
import { ProjectCheckoutMobileLayout } from '@/modules/project-checkout/components/ProjectCheckoutLayout/ProjectCheckoutMobileLayout';
import { ProjectCheckoutSummaryContainer } from '@/modules/project-checkout/containers/ProjectCheckoutSummary.container';
import { createProjectCheckoutForm } from '@/modules/project-checkout/project-checkout.form';
import {
  ProjectCheckoutStep,
  useCreateProjectCheckoutManager,
  useCurrentStep,
} from '@/modules/project-checkout/project-checkout.manager';
import { PROJECT_CHECKOUT_STEPS } from '@/modules/project-checkout/project-checkout.steps';
import { getProjectCheckoutCoupons } from '@/modules/project-checkout/project-checkout.utils';
import {
  getCheckoutAudiencePath,
  getCheckoutAutobiographyContactPath,
  getCheckoutBabyHostPath,
  getCheckoutBiographyDeliveryPath,
  getCheckoutGiftPath,
  getSigninPath,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';

export interface ProjectCheckoutAudienceContainerProps {
  projectType: 'BIOGRAPHY' | 'BABYBOOK';
}

export function ProjectCheckoutAudienceContainer({ projectType }: ProjectCheckoutAudienceContainerProps) {
  const isMobile = useIsMobileViewport();

  const { redirectService, checkoutAnalyticsService } = useServices();
  const user = useUser();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [selectedAudienceTarget, setSelectedAudienceTarget] = useState(
    projectType === 'BIOGRAPHY' ? AudienceTarget.SomeoneElse : AudienceTarget.Myself,
  );

  const personQuery = usePersonQuery(user?.personId);
  const signedInUser = useMemo(() => {
    const name = personQuery?.data?.name ?? null;
    const email = user?.communicationChannels.email ?? '';

    return {
      name,
      email,
    };
  }, [user, personQuery?.data]);

  const title = useMemo(() => {
    if (projectType === 'BABYBOOK') {
      return 'I’d like to...';
    }

    return 'Who will be telling stories?';
  }, [projectType]);

  const audienceOptions = useMemo(() => {
    if (projectType === 'BABYBOOK') {
      return [
        { label: 'Create my own baby book', value: AudienceTarget.Myself },
        { label: 'Gift a baby book', value: AudienceTarget.SomeoneElse },
      ];
    }

    return [
      { label: 'Me', value: AudienceTarget.Myself },
      { label: 'A loved one', value: AudienceTarget.SomeoneElse },
    ];
  }, [projectType]);

  // In this page. the manager is only used to show the summary.
  // Another instance will be created in the next step.
  const coupons = useMemo(() => (user !== undefined ? getProjectCheckoutCoupons(user?.coupons) : []), [user]);
  const manager = useCreateProjectCheckoutManager(
    useMemo(
      () =>
        createProjectCheckoutForm({
          addons: { books: 1 },
          type: projectType === 'BABYBOOK' ? 'BABYBOOK' : 'BIOGRAPHY',
          referrerUserId: getQueryParam('referrer-user-id') ?? Cookies.get('referrer-user-id') ?? undefined,
        }),
      [projectType],
    ),
    coupons,
    PROJECT_CHECKOUT_STEPS[projectType === 'BABYBOOK' ? 'BABYBOOK' : 'BIOGRAPHY'],
  );
  const currentStep = useCurrentStep(manager);

  // Callbacks
  const handleSignOut = useCallback(async () => {
    setInputValues(manager.form, {
      owner: {
        person: {
          firstName: '',
          lastName: '',
        },
        email: '',
        phone: {
          countryCode: '',
          number: '',
        },
      },
    });
    await signOut(false);
  }, [manager.form, signOut]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect(
      'signed-in',
      getCheckoutAudiencePath(projectType === 'BABYBOOK' ? 'baby' : 'biography'),
    );
    navigate(getSigninPath({ backupLocalData: true }));
  }, [projectType, navigate, redirectService]);

  const handleGoToNextStep = useCallback(async () => {
    checkoutAnalyticsService.onCheckoutPersonalized(selectedAudienceTarget);

    let nextStep: string;
    switch (projectType) {
      case 'BIOGRAPHY':
        nextStep =
          selectedAudienceTarget === AudienceTarget.SomeoneElse
            ? getCheckoutBiographyDeliveryPath()
            : getCheckoutAutobiographyContactPath();
        break;

      case 'BABYBOOK': {
        if (selectedAudienceTarget === AudienceTarget.SomeoneElse) {
          nextStep = getCheckoutGiftPath();
        } else {
          nextStep = getCheckoutBabyHostPath();
        }
        break;
      }

      default:
        throw new Error(`The ${projectType} project is not implemented.`);
    }

    navigate(nextStep + window.location.search);
  }, [checkoutAnalyticsService, navigate, projectType, selectedAudienceTarget]);

  const handlePurchaseGiftCard = useCallback(() => {
    navigate(getCheckoutGiftPath());
  }, [navigate]);

  const checkoutAudienceForm = (
    <ProjectCheckoutAudienceForm
      selectedValue={selectedAudienceTarget}
      onSelectedChange={(selected: AudienceTarget) => setSelectedAudienceTarget(selected)}
      user={user ? signedInUser : null}
      title={title}
      audienceOptions={audienceOptions}
      onSignIn={handleSignIn}
      onSignOut={handleSignOut}
      onPurchaseGiftCard={projectType !== 'BABYBOOK' ? handlePurchaseGiftCard : null}
    />
  );

  const steps = useMemo(() => {
    let steps: ProjectCheckoutStep[];
    if (projectType === 'BABYBOOK') {
      steps = PROJECT_CHECKOUT_STEPS['BABYBOOK'];
    } else if (selectedAudienceTarget === AudienceTarget.Myself) {
      steps = PROJECT_CHECKOUT_STEPS['AUTOBIOGRAPHY'];
    } else {
      steps = PROJECT_CHECKOUT_STEPS['BIOGRAPHY'];
    }

    return steps.map((s, i) => ({
      label: s.label,
      disabled: i > 0,
    }));
  }, [projectType, selectedAudienceTarget]);

  const Stepper = useMemo(
    () => <RMStepper layout={isMobile ? 'progress' : 'stepper'} activeStepIndex={0} steps={steps} />,
    [isMobile, steps],
  );

  // Analytics
  useEffect(() => {
    checkoutAnalyticsService.onCheckoutArrived('book');
  }, [checkoutAnalyticsService]);

  if (isMobile) {
    return (
      <ProjectCheckoutMobileLayout
        Stepper={Stepper}
        ContinueButton={
          <RMButton background="primary" onClick={handleGoToNextStep} fullWidth autoLoading>
            Continue
          </RMButton>
        }
        Header={<ProjectCheckoutSummaryContainer manager={manager} />}
        currentStep={currentStep}
      >
        {checkoutAudienceForm}
      </ProjectCheckoutMobileLayout>
    );
  }

  return (
    <ProjectCheckoutDesktopLayout
      Stepper={Stepper}
      ContinueButton={
        <RMButton background="primary" onClick={handleGoToNextStep} fullWidth autoLoading>
          Continue
        </RMButton>
      }
      RightPanel={<ProjectCheckoutSummaryContainer manager={manager} />}
      currentStep={currentStep}
      user={user ? signedInUser : null}
      showPurchaseGiftCard={projectType !== 'BABYBOOK'}
      onSignIn={handleSignIn}
      onSignOut={handleSignOut}
      onPurchaseGiftCard={handlePurchaseGiftCard}
    >
      {checkoutAudienceForm}
    </ProjectCheckoutDesktopLayout>
  );
}
