import { useMemo } from 'react';

import { RMText } from '@/components/RMText/RMText.js';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';

import { ExperimentalProjectCheckoutSummary } from '../components/ExperimentalProjectCheckoutSummary/ExperimentalProjectCheckoutSummary.js';
import { ExperimentalProjectCheckoutSummaryMobile } from '../components/ExperimentalProjectCheckoutSummary/ExperimentalProjectCheckoutSummary.mobile.js';
import { CartItem } from '../components/ExperimentalProjectCheckoutSummary/ExperimentalProjectCheckoutSummary.types.js';
import { ExperimentalProjectCheckoutSummaryAccordion } from '../components/ExperimentalProjectCheckoutSummaryAccordion/ExperimentalProjectCheckoutSummaryAccordion.js';
import { ProjectCheckoutManager, useCheckoutTotal } from '../project-checkout.manager.js';

const LEGACYBOX_QUANTITY_PRICE_MAP: Record<string, number> = {
  25: 30.98,
  50: 40.98,
  100: 55.98,
  250: 105.98,
  500: 155.98,
  1000: 255.98,
};

export interface ExperimentalProjectCheckoutSummaryContainerProps {
  manager: ProjectCheckoutManager;
  accordion?: boolean;
}

export function ExperimentalProjectCheckoutSummaryContainer({
  manager,
  accordion,
}: ExperimentalProjectCheckoutSummaryContainerProps) {
  const isMobile = useIsMobileViewport();
  const total = useCheckoutTotal(manager);

  const cartItems = useMemo<CartItem[]>(() => {
    let priceWithoutDiscounts = 99;

    const items: CartItem[] = [
      {
        type: 'project',
        name: '1 year of unlimited storytelling',
        price: 99,
        description: [{ text: 'Billed annually, cancel anytime' }],
      },
      {
        type: 'included-book',
        name: '1 hardcover book',
        price: 0,
        description: [
          { text: 'Includes color printing & up to 200 pages' },
          {
            text: 'Free shipping in the US',
            tooltip: (
              <RMText type="sans" size="xxs" color="inverse-on-surface-primary">
                International shipping to the UK, Canada, Ireland, Australia, and New Zealand is available for an
                additional fee. Shipping fees will be charged separately when your book is ready to print. Rates vary by
                destination.&nbsp;
                <a href="https://intercom.help/remento/en/articles/8365901-where-can-remento-ship-to" target="_blank">
                  <RMText type="sans" size="xxs" color="inverse-on-surface-primary" underline>
                    Learn more
                  </RMText>
                </a>
                .
              </RMText>
            ),
          },
        ],
      },
    ];

    // TODO: When support free projects, we need to fix this
    const books = total.products.books - 1;
    if (books > 0) {
      const booksPrice = 59 * books;
      priceWithoutDiscounts += booksPrice;
      items.push({
        type: 'extra-book',
        name: `${books} additional book${books > 1 ? 's' : ''} (discounted)`,
        price: booksPrice,
        description: [
          { text: 'Includes color printing & up to 200 pages' },
          {
            text: 'Free shipping in the US',
            tooltip: (
              <RMText type="sans" size="xxs" color="inverse-on-surface-primary">
                International shipping to the UK, Canada, Ireland, Australia, and New Zealand is available for an
                additional fee. Shipping fees will be charged separately when your book is ready to print. Rates vary by
                destination.&nbsp;
                <a href="https://intercom.help/remento/en/articles/8365901-where-can-remento-ship-to" target="_blank">
                  <RMText type="sans" size="xxs" color="inverse-on-surface-primary" underline>
                    Learn more
                  </RMText>
                </a>
                .
              </RMText>
            ),
          },
        ],
      });
    }

    if (total.products.ebook) {
      const ebookPrice = 24.99;
      priceWithoutDiscounts += ebookPrice;
      items.push({
        type: 'ebook',
        name: 'E-book (discounted)',
        price: ebookPrice,
        description: [{ text: 'Access to your book in an easy-to-download digital format' }],
      });
    }

    if (total.products.legacyboxQuantity > 0) {
      const legacyboxPrice = LEGACYBOX_QUANTITY_PRICE_MAP[total.products.legacyboxQuantity];
      priceWithoutDiscounts += legacyboxPrice;
      items.push({
        type: 'legacybox',
        name: `${total.products.legacyboxQuantity} photos digitized with Legacybox`,
        price: legacyboxPrice,
        description: [{ text: `Includes round-trip shipping for $5.99` }],
      });
    }

    if (total.coupon != null && total.status === 'fetched' && total.isCouponValid) {
      items.push({
        type: 'discount',
        name: total.couponName ?? 'Coupon discount',
        price: total.price - priceWithoutDiscounts,
        description: [],
      });
    }

    return items;
  }, [total]);

  if (isMobile) {
    if (accordion) {
      return (
        <ExperimentalProjectCheckoutSummaryAccordion
          total={total.status === 'fetched' ? total.price : null}
          items={cartItems}
        />
      );
    }
    return (
      <ExperimentalProjectCheckoutSummaryMobile
        total={total.status === 'fetched' ? total.price : null}
        items={cartItems}
      />
    );
  }

  return (
    <ExperimentalProjectCheckoutSummary total={total.status === 'fetched' ? total.price : null} items={cartItems} />
  );
}
