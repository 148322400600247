import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

export const DetailsRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
  max-width: 600px;

  body[data-mobile='true'] & {
    padding-top: var(--spacing-xl);
    gap: var(--spacing-md);
  }
`;

export const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-3xl);
  width: 100%;
  background-color: var(--surface-bright);
  border-radius: var(--radius-smooth);

  body[data-mobile='true'] & {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Banner = styled.img`
  max-height: 270px;
  object-fit: cover;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);

  body[data-mobile='true'] & {
    gap: var(--spacing-2xs);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const FeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const FeaturesListItem = styled.li`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
`;

export const FeaturesListItemIcon = styled(FontAwesomeIcon)`
  margin-top: 2px;
`;

export const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const Timeline = styled.div``;

export const TimelineSection = styled.div`
  position: relative;
  padding-bottom: var(--spacing-xl);

  &:last-child {
    padding-bottom: 0;
  }
`;

export const TimelineLine = styled.div`
  position: absolute;
  left: calc(1rem - 0.5px);
  width: 1px;
  height: 100%;
  background-color: var(--border-weak);

  &[data-last='true'] {
    height: calc(100% - var(--spacing-2xs));
  }
`;

export const TimelineHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
`;

export const TimelineHeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > span:first-child {
    line-height: 2rem;
  }
`;

export const TimelineIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: hsla(180, 22%, 50%, 1);
  border-radius: var(--radius-full);
  z-index: 1;
`;

export const TimelineIcon = styled(FontAwesomeIcon)`
  color: hsla(171, 31%, 80%, 1);
`;

export const TimelineStepList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding-top: var(--spacing-md);
`;

export const TimelineStepListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-2xs) 7px;
  z-index: 1;
`;

export const TimelineStepListItemIcon = styled.div`
  align-self: flex-start;
  flex: 0 0 19px;
  width: 19px;
  height: 18px;
  border-radius: var(--radius-full);
  border: 2px solid var(--border-weak);
  background-color: var(--surface-bright);
`;

export const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-lg);
  border-radius: var(--radius-smooth);
  border: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-bottom-1);
  background-color: var(--surface-brightest);
`;

export const ReviewStars = styled.img`
  align-self: self-start;
  height: 16px;
`;

export const NoteCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md) var(--spacing-3xl);
  width: 100%;
  background-color: var(--surface-bright);
  border-radius: var(--radius-smooth);

  body[data-mobile='true'] & {
    padding: var(--spacing-md);
  }
`;
