import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RMButton } from '@/components/RMButton/RMButton';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useEnumQueryParam } from '@/hooks/useQueryParam';
import {
  AudienceOption,
  AudienceTarget,
  ProjectCheckoutAudienceForm,
} from '@/modules/project-checkout/components/ProjectCheckoutAudienceForm/ProjectCheckoutAudienceForm';
import {
  getCheckoutAudiencePath,
  getCheckoutAutobiographyContactPath,
  getCheckoutBiographyDeliveryPath,
  getCheckoutGiftPath,
  getSigninPath,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';

import { ExperimentalProjectCheckoutAudienceDetails } from '../components/ExperimentalProjectCheckoutAudienceDetails/ExperimentalProjectCheckoutAudienceDetails.js';
import { getCheckoutAudienceDetailsProps } from '../components/ExperimentalProjectCheckoutAudienceDetails/ExperimentalProjectCheckoutAudienceDetails.utils.js';
import {
  ExperimentalProjectCheckoutGiftTypeForm,
  GiftType,
} from '../components/ExperimentalProjectCheckoutGiftTypeForm/ExperimentalProjectCheckoutGiftTypeForm.js';
import { ExperimentalProjectCheckoutLayout } from '../components/ExperimentalProjectCheckoutLayout/ExperimentalProjectCheckoutLayout.js';
import { ExperimentalProjectCheckoutMobileLayout } from '../components/ExperimentalProjectCheckoutLayout/ExperimentalProjectCheckoutMobileLayout.js';

export function ExperimentalProjectCheckoutAudienceContainer() {
  const isMobile = useIsMobileViewport();

  const { redirectService, checkoutAnalyticsService } = useServices();
  const user = useUser();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const personQuery = usePersonQuery(user?.personId);
  const signedInUser = useMemo(() => {
    const name = personQuery?.data?.name ?? null;
    const email = user?.communicationChannels.email ?? '';

    return {
      name,
      email,
    };
  }, [user, personQuery?.data]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', getCheckoutAudiencePath('biography'));
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate, redirectService]);

  // Screen state
  const [currentStep, setCurrentStep] = useEnumQueryParam('step', 'audience', ['audience', 'gift-type']);
  const [audience, setAudience] = useState(AudienceTarget.SomeoneElse);
  const [giftType, setGiftType] = useState(GiftType.GUIDED);

  const options = useMemo<AudienceOption[]>(() => {
    return [
      { label: 'Gift a book to someone', value: AudienceTarget.SomeoneElse },
      { label: 'Create my own book', value: AudienceTarget.Myself },
    ];
  }, []);

  const handleGoToNextStep = useCallback(async () => {
    switch (currentStep) {
      case 'audience': {
        checkoutAnalyticsService.onCheckoutPersonalized(audience);
        if (audience === AudienceTarget.SomeoneElse) {
          setCurrentStep('gift-type');
          return;
        }
        navigate(getCheckoutAutobiographyContactPath() + window.location.search);
        break;
      }
      case 'gift-type': {
        if (giftType === GiftType.GUIDED) {
          navigate(getCheckoutBiographyDeliveryPath() + window.location.search);
          return;
        }
        navigate(getCheckoutGiftPath(true));
        break;
      }
    }
  }, [audience, checkoutAnalyticsService, currentStep, giftType, navigate, setCurrentStep]);

  // Analytics
  useEffect(() => {
    checkoutAnalyticsService.onCheckoutArrived('book');
  }, [checkoutAnalyticsService]);

  // Render
  const checkoutAudienceForm = (
    <ProjectCheckoutAudienceForm
      title="What brings you to Remento?"
      audienceOptions={options}
      selectedValue={audience}
      onSelectedChange={setAudience}
      onPurchaseGiftCard={null}
    />
  );

  const checkoutGiftTypeForm = (
    <ExperimentalProjectCheckoutGiftTypeForm selectedValue={giftType} onSelectedChange={setGiftType} />
  );

  const checkoutDetails = (
    <ExperimentalProjectCheckoutAudienceDetails {...getCheckoutAudienceDetailsProps(currentStep, audience, giftType)} />
  );

  const continueButton = (
    <RMButton background="primary" onClick={handleGoToNextStep} fullWidth autoLoading>
      {(currentStep === 'audience' && audience === AudienceTarget.SomeoneElse) ||
      (currentStep === 'gift-type' && giftType === GiftType.GUIDED)
        ? 'Schedule your gift'
        : 'Continue'}
    </RMButton>
  );

  if (isMobile) {
    return (
      <ExperimentalProjectCheckoutMobileLayout
        Stepper={null}
        Summary={null}
        ContinueButton={continueButton}
        currentStep={currentStep}
        stickyFooter
        user={user ? signedInUser : null}
        onSignIn={handleSignIn}
        onSignOut={signOut}
      >
        {currentStep === 'audience' ? checkoutAudienceForm : checkoutGiftTypeForm}
        {checkoutDetails}
      </ExperimentalProjectCheckoutMobileLayout>
    );
  }

  return (
    <ExperimentalProjectCheckoutLayout
      Stepper={null}
      ContinueButton={continueButton}
      RightPanel={checkoutDetails}
      currentStep={currentStep}
      user={user ? signedInUser : null}
      onSignIn={handleSignIn}
      onSignOut={signOut}
    >
      {currentStep === 'audience' ? checkoutAudienceForm : checkoutGiftTypeForm}
    </ExperimentalProjectCheckoutLayout>
  );
}
