import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { StorytellerOption, useStorytellerOptions } from '@/modules/project-checkout/hooks/useStorytellerOptions';
import { getCheckoutFreeDetails, getCheckoutPath, RementoPage } from '@/modules/routing';
import { useUser } from '@/services/api/auth/auth.service.hook';

import {
  Body,
  ContinueButton,
  Divider,
  Footer,
  Header,
  Link,
  PickerWrapper,
  StyledNewProjectPage,
  Wordmark,
} from './NewProjectPage.styles';

function InternalNewProjectPage() {
  // Services
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  // Queries
  const user = useUser();

  // State
  const storytellers = useStorytellerOptions();
  const [selectedStoryteller, setSelectedStoryteller] = useState<StorytellerOption | null>(null);

  // Callbacks
  const handleContinue = useCallback(() => {
    if (selectedStoryteller == null) {
      return;
    }
    navigate(getCheckoutFreeDetails(selectedStoryteller.personId, selectedStoryteller.subscriptionOwnerPersonId));
  }, [navigate, selectedStoryteller]);

  const handlePurchaseNewBook = useCallback(() => {
    navigate(getCheckoutPath());
  }, [navigate]);

  if (user == null) {
    // The user must be signed-in to access the page, so eventually we will have a user.
    return <PageLoader />;
  }

  const continueButton = (
    <ContinueButton background="primary" disabled={selectedStoryteller == null} autoLoading onClick={handleContinue}>
      Continue
    </ContinueButton>
  );

  return (
    <StyledNewProjectPage>
      <Header>
        <Wordmark />
      </Header>
      <Divider />
      <Body>
        <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary" align="center">
          Select the storyteller for this project
        </RMText>
        <PickerWrapper>
          <RMPicker.Root>
            {storytellers?.map((storyteller) => (
              <RMPicker.Item
                bold="medium"
                value={`${storyteller.personId}-${storyteller.subscriptionOwnerPersonId}`}
                label={storyteller.label}
                selected={storyteller.value === selectedStoryteller?.value}
                onClick={() => {
                  setSelectedStoryteller(storyteller.value === selectedStoryteller?.value ? null : storyteller);
                }}
              />
            ))}
          </RMPicker.Root>
        </PickerWrapper>
        {isMobile == false && continueButton}
        <Link type="sans" size="xs" color="on-surface-primary" align="center" bold onClick={handlePurchaseNewBook}>
          Or purchase a book for a new Storyteller {'>'}
        </Link>
        {isMobile == true && <Footer>{continueButton}</Footer>}
      </Body>
    </StyledNewProjectPage>
  );
}

export function NewProjectPage() {
  return (
    <RementoPage type="default">
      <InternalNewProjectPage />
    </RementoPage>
  );
}
