import { RMText } from '@/components/RMText/RMText';

import { LoginLink } from './ProjectCheckoutLoginLink.styles.js';

export interface ProjectCheckoutLoginLinkProps {
  onClick: () => void;
}

export function ProjectCheckoutLoginLink({ onClick }: ProjectCheckoutLoginLinkProps) {
  return (
    <div role="region" ria-label="Login information">
      <RMText type="sans" size="xs" color="on-surface-primary">
        Already have an account with Remento?{' '}
        <LoginLink onClick={onClick} role="link" aria-label="Login here">
          Log in here
        </LoginLink>{' '}
        to purchase.
      </RMText>
    </div>
  );
}
