import { useEffect, useMemo } from 'react';

import { getCurrentTimezoneInfo } from '@/utils/tz';

import { BiographyProjectCheckoutDeliveryForm } from '../components/BiographyProjectCheckoutDeliveryForm/BiographyProjectCheckoutDeliveryForm.js';
import { useProjectCheckoutManager } from '../project-checkout.manager.context.js';
import { goToStep } from '../project-checkout.manager.js';
import { useIsExperimentalProjectCheckoutLayoutEnabled } from '../project-checkout.utils.js';

export function BiographyProjectCheckoutDeliveryStepContainer() {
  const manager = useProjectCheckoutManager();

  const timezone = useMemo(() => getCurrentTimezoneInfo().shortAbbreviation, []);

  // AB testing
  const experimentalLayout = useIsExperimentalProjectCheckoutLayoutEnabled('BIOGRAPHY');

  useEffect(() => {
    goToStep(manager, 'delivery');
  }, [manager]);

  return (
    <BiographyProjectCheckoutDeliveryForm
      form={manager.form}
      timezone={timezone}
      showPrintableCardOption={experimentalLayout}
    />
  );
}
