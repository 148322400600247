import { styled } from '@linaria/react';

export const AccordionRoot = styled.aside`
  position: relative;
  border-top: 1px solid var(--border-weak);
  border-bottom: 2px solid var(--color-spruce-40-16);
  background-color: var(--color-spruce-40-8);
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: var(--spacing-md);
`;

export const HeaderContent = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  > svg {
    height: 16px;
    width: 16px;
    transition: all 250ms;
    rotate: ${(props) => (props.open ? '90deg' : '0')};
  }
`;

export const Absolute = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  z-index: 100;
`;
