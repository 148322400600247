import { styled } from '@linaria/react';

export const StyledGiftCardForm = styled.div`
  display: flex;
  flex-flow: column;

  body[data-mobile='true'] & {
    align-items: center;
    height: 100%;
  }
`;

export const PickerWrapper = styled.div`
  width: 100%;
  max-width: 380px;

  @media only screen and (max-width: 768px) {
    max-width: unset;
  }
`;
