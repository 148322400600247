import { PropsWithChildren, ReactNode, useLayoutEffect, useMemo } from 'react';
import { PersonName } from '@remento/types/person';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { useHasScroll } from '@/hooks/useElementSize.js';
import { getScrollbarWidth } from '@/utils/scrollbar.js';

import { ProjectCheckoutLoginLink } from '../ProjectCheckoutLoginLink/ProjectCheckoutLoginLink.js';
import { ProjectCheckoutLogoutLink } from '../ProjectCheckoutLogoutLink/ProjectCheckoutLogoutLink.js';

import {
  Divider,
  Header,
  Left,
  LeftForm,
  LeftFormButton,
  Main,
  MainContent,
  Right,
  StepperWrapper,
  StyledProjectCheckoutDesktopLayout,
  Wordmark,
} from './ExperimentalProjectCheckoutLayout.styles.js';

export interface SignedInUser {
  name: PersonName | null;
  email: string;
}

export type ExperimentalProjectCheckoutDesktopLayoutProps = PropsWithChildren<{
  Stepper: ReactNode;
  RightPanel: ReactNode;
  ContinueButton: ReactNode;
  currentStep: string;
  user: SignedInUser | null;
  onSignIn: () => void;
  onSignOut: (redirect?: boolean) => Promise<void>;
}>;

export function ExperimentalProjectCheckoutLayout({
  Stepper,
  RightPanel,
  ContinueButton,
  currentStep,
  user,
  onSignIn,
  onSignOut,
  children,
}: ExperimentalProjectCheckoutDesktopLayoutProps) {
  const [scrollAreaRef, hasScroll, scrollAreaElement] = useHasScroll();
  const scrollbarWidth = useMemo(() => getScrollbarWidth(), []);

  useLayoutEffect(() => {
    if (scrollAreaElement == null) {
      return;
    }
    scrollAreaElement.scrollTop = 0;
  }, [scrollAreaElement, currentStep]);

  return (
    <StyledProjectCheckoutDesktopLayout>
      <Header>
        <Wordmark destination="marketing" />
      </Header>
      <Divider />
      <Main ref={scrollAreaRef}>
        <MainContent style={{ paddingLeft: hasScroll ? scrollbarWidth + 'px' : undefined }}>
          <Left>
            <LeftForm>
              <StepperWrapper role="navigation">{Stepper}</StepperWrapper>
              <RMSpacer spacing="3xl" direction="column" />
              {children}
              <RMSpacer spacing="2xl" direction="column" />
              <LeftFormButton role="group">{ContinueButton}</LeftFormButton>
              {user ? (
                <>
                  <RMSpacer spacing="xl" direction="column" />
                  <ProjectCheckoutLogoutLink onSignOut={onSignOut} user={user} />
                </>
              ) : (
                <>
                  <RMSpacer spacing="xl" direction="column" />
                  <ProjectCheckoutLoginLink onClick={onSignIn} />
                </>
              )}
            </LeftForm>
          </Left>
          <Right>{RightPanel}</Right>
        </MainContent>
      </Main>
    </StyledProjectCheckoutDesktopLayout>
  );
}
