import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BabyBookSrc from '@/assets/remento-baby-book.webp';
import BiographyBookSrc from '@/assets/remento-book.webp';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getCheckoutAudiencePath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { CheckoutType } from '@/services/api/checkout';

import {
  Body,
  CardBottom,
  CardImage,
  CardImageWrapper,
  CardsContainer,
  CardText,
  CardTitle,
  CardTop,
  CardWrapper,
  Header,
  MobileHeader,
  StyledProjectCheckoutSelection,
  TextContainer,
  Wordmark,
} from './ProjectCheckoutPage.styles';

function InternalProjectCheckoutPage() {
  const { checkoutAnalyticsService } = useServices();
  const navigate = useNavigate();

  const isMobile = useIsMobileViewport();

  const handleSelect = useCallback(
    async (checkoutType: CheckoutType) => {
      let project: 'biography' | 'baby';
      switch (checkoutType) {
        case CheckoutType.BABYBOOK:
          project = 'baby';
          break;
        case CheckoutType.BIOGRAPHY:
        case CheckoutType.AUTOBIOGRAPHY:
        default:
          project = 'biography';
          break;
      }

      navigate(getCheckoutAudiencePath(project));
    },
    [navigate],
  );

  useEffect(() => {
    checkoutAnalyticsService.onCheckoutArrived('book');
  }, [checkoutAnalyticsService]);

  return (
    <StyledProjectCheckoutSelection>
      {isMobile ? (
        <MobileHeader>
          <Wordmark color="neutral" />
        </MobileHeader>
      ) : (
        <Header>
          <Wordmark />
        </Header>
      )}

      <Body>
        <TextContainer>
          <RMText as="h1" size={isMobile ? 'l' : 'xl'} type="serif" color="on-surface-primary">
            Which book would you like to create?
          </RMText>
        </TextContainer>
        <CardsContainer>
          <CardWrapper
            onClick={() => handleSelect(CheckoutType.BIOGRAPHY)}
            role="link"
            aria-labelledby="biography-book-label"
            aria-describedby="biography-book-description"
          >
            <CardTop>
              <CardImageWrapper>
                <CardImage src={BiographyBookSrc} alt="Biography book" aria-hidden="true" />
              </CardImageWrapper>
            </CardTop>
            <CardBottom>
              <CardTitle id="biography-book-label">The Remento Book</CardTitle>
              <CardText id="biography-book-description">
                Turn memories of the past into a book of written stories.
              </CardText>
            </CardBottom>
          </CardWrapper>

          <CardWrapper
            onClick={() => handleSelect(CheckoutType.BABYBOOK)}
            role="link"
            aria-labelledby="babybook-book-label"
            aria-describedby="babybook-book-description"
          >
            <CardTop>
              <CardImageWrapper>
                <CardImage src={BabyBookSrc} alt="Babybook book" aria-hidden="true" />
              </CardImageWrapper>
            </CardTop>
            <CardBottom>
              <CardTitle id="babybook-book-label">The Remento Baby Book</CardTitle>
              <CardText id="babybook-book-description">
                Capture your baby’s earliest moments, and the memory behind each one.
              </CardText>
            </CardBottom>
          </CardWrapper>
        </CardsContainer>
      </Body>
    </StyledProjectCheckoutSelection>
  );
}

export function ProjectCheckoutPage() {
  return (
    <RementoPage type="empty">
      <InternalProjectCheckoutPage />
    </RementoPage>
  );
}
