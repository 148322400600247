import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledProjectCheckoutSuccessPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

export const PageLoaderWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
  height: 100%;
  max-width: 410px;
`;

export const PageLoaderSlowLoadWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const PageLoaderSupportAnchor = styled.a`
  position: absolute;
  bottom: var(--spacing-5xl);

  body[data-mobile='true'] & {
    bottom: var(--spacing-md);
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  > div {
    height: 300px;
    width: 300px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const Header = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '0 var(--spacing-lg)' : '0 var(--spacing-3xl)')};
  height: ${(props) => (props.isMobile ? '3.5rem' : '5rem')};
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wordmark = styled(RMWordmark)`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
  }
`;
