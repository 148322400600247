import { faBookOpenCover, faGift, faVideo } from '@fortawesome/pro-solid-svg-icons';

import GiftCardBannerSrc from '@/assets/checkout-audience-gift-card.webp';
import MyselfBannerSrc from '@/assets/checkout-audience-myself.webp';
import SomeoneElseBannerSrc from '@/assets/checkout-audience-someone-else.webp';

import { GiftType } from '../ExperimentalProjectCheckoutGiftTypeForm/ExperimentalProjectCheckoutGiftTypeForm.js';
import { AudienceTarget } from '../ProjectCheckoutAudienceForm/ProjectCheckoutAudienceForm.js';

import { CheckoutAudienceDetailsRenderProps } from './ExperimentalProjectCheckoutAudienceDetails.js';

export function getCheckoutAudienceDetailsProps(
  currentStep: 'audience' | 'gift-type',
  audience: AudienceTarget,
  giftType: GiftType,
): CheckoutAudienceDetailsRenderProps {
  if (currentStep === 'audience') {
    switch (audience) {
      case AudienceTarget.SomeoneElse: {
        return {
          banner: {
            src: SomeoneElseBannerSrc,
          },
          title: 'The Remento Life Story Book',
          subtitle: 'Gift their stories to future generations, effortlessly.',
          features: [
            'Easy to use for all ages',
            'Recordings automatically turned into written stories',
            'Full editing control',
            'A book up to 200 pages included in your purchase',
            'Secure and private (you own your stories)',
            'Renews annually; cancel anytime',
          ],
          testimonialReview: {
            title: 'Easy to use for my 80-year-old Grandfather - and us!',
            message:
              'We gifted Remento to my grandfather and he’s completely loving it. Remento is easy to use for both the loved one recording and the collaborators choosing the prompts. Customer service is also QUICK to answer our questions. 11/10 recommend this company.',
            author: 'Kim M.',
          },
        };
      }
      case AudienceTarget.Myself: {
        return {
          banner: {
            src: MyselfBannerSrc,
          },
          title: 'The Remento Life Story Book',
          subtitle: 'It’s time to share your story, no writing required!',
          detail: 'Renews annually. Cancel anytime',
          timeline: {
            title: 'How it works',
            sections: [
              {
                id: 'storytelling-begins',
                title: 'Storytelling begins',
                subtitle: 'Over the course of the year',
                icon: faVideo,
                items: [
                  'Choose prompts from Remento or write your own',
                  'Receive prompts weekly',
                  'Record your memories',
                  'Invite family & friends to hear your stories',
                ],
              },
              {
                id: 'create-book',
                title: 'Create the book',
                subtitle: 'Print when you’re ready',
                icon: faBookOpenCover,
                items: ['Edit stories & add photos anytime', 'Book ordered & delivered'],
              },
            ],
          },
          testimonialReview: {
            title: 'Bringing me closer with my family',
            message:
              'Simple and Easy. The prompts are a great way to spark a memory. I have very positive feedback from my family. Their only advice… more, more, more!',
            author: 'Dorothy M. ',
          },
        };
      }
    }
  }

  switch (giftType) {
    case GiftType.GUIDED: {
      return {
        timeline: {
          title: 'How it works',
          sections: [
            {
              id: 'gift-delivered',
              title: 'Gift delivered',
              subtitle: 'Immediately or on scheduled date',
              icon: faGift,
              items: [],
            },
            {
              id: 'storytelling-begins',
              title: 'Storytelling begins',
              subtitle: 'Over the course of the year',
              icon: faVideo,
              items: [
                'Prompts arrive weekly',
                'Storyteller records their responses ',
                'Family connects over beautiful memories',
              ],
            },
            {
              id: 'create-the-book',
              title: 'Create the book',
              subtitle: 'Print when you’re ready',
              icon: faBookOpenCover,
              items: ['Edit stories & add photos anytime', 'Book ordered & delivered'],
            },
          ],
        },
      };
    }
    case GiftType.GIFT_ONLY: {
      return {
        banner: {
          src: GiftCardBannerSrc,
          maxWidth: '335px',
        },
        title: 'The Remento Gift Card',
        subtitle: 'For anyone with amazing stories worth sharing.',
        pricePrefix: 'Starting at',
        features: [
          'Gift card includes one year of recording stories + a hardcover, keepsake book up to 200 pages',
          'They select their topics and record their stories',
          'Remento turns recordings into written stories. They have full editing control',
          'Secure and private (you own your stories)',
        ],
        testimonialReview: {
          title: 'Easy to use',
          message:
            'This is absolutely the best way to save your memories. Easy to use, the prompts help in the best way to arrange your thoughts. Great help by the team if needed. I feel very blessed to have found it.',
          author: 'Roland M.',
        },
        note: 'Note: Discount codes cannot be applied to gift card purchases',
      };
    }
  }
}
