import { Form } from './@types.js';
import { Paths } from './@types-utils.js';
import { getInputValidation, watchInputValidation } from './input.js';

export function everyInput<In extends object, Out extends object = In>(
  form: Form<In, Out>,
  inputs: Array<Paths<In>>,
  evaluate: (input: Paths<In>, form: Form<In, Out>) => boolean,
): boolean {
  return inputs.every((input) => evaluate(input, form));
}

export function everyInputValid<In extends object, Out extends object = In>(
  form: Form<In, Out>,
  inputs: Array<Paths<In>>,
): boolean {
  return everyInput(form, inputs, (input, form) => getInputValidation(form, input).isValid ?? false);
}

export function watchEveryInput<In extends object, Out extends object = In>(
  form: Form<In, Out>,
  inputs: Array<Paths<In>>,
  delayed: boolean,
  evaluate: (input: Paths<In>, form: Form<In, Out>) => boolean,
  callback: (result: boolean) => void,
): () => void {
  let lastFiredValue = everyInput(form, inputs, evaluate);

  const unsubscribeFunctions = inputs.map((input) => {
    return watchInputValidation(
      form,
      input,
      () => {
        const result = everyInput(form, inputs, evaluate);
        if (lastFiredValue !== result) {
          lastFiredValue = result;
          callback(result);
        }
      },
      delayed,
    );
  });

  return () => {
    unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
  };
}

export function watchEveryInputValid<In extends object, Out extends object = In>(
  form: Form<In, Out>,
  inputs: Array<Paths<In>>,
  delayed: boolean,
  callback: (isValid: boolean) => void,
): () => void {
  return watchEveryInput(
    form,
    inputs,
    delayed,
    (input, form) => getInputValidation(form, input).isValid ?? false,
    callback,
  );
}
