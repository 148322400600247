import { ExperimentalProjectCheckoutAudienceContainer } from '@/modules/project-checkout/containers/ExperimentalProjectCheckoutAudience.container';
import { ProjectCheckoutAudienceContainer } from '@/modules/project-checkout/containers/ProjectCheckoutAudience.container';
import { useIsExperimentalProjectCheckoutLayoutEnabled } from '@/modules/project-checkout/project-checkout.utils';
import { ProjectCheckoutServicesProvider } from '@/modules/project-checkout/project-checkout-services.context.';
import { RementoPage } from '@/modules/routing';

interface InternalProjectCheckoutAudiencePage {
  projectType: 'BIOGRAPHY' | 'BABYBOOK';
}

function InternalProjectCheckoutAudiencePage({ projectType }: InternalProjectCheckoutAudiencePage) {
  // AB Testing
  const experimentalLayout = useIsExperimentalProjectCheckoutLayoutEnabled(projectType);

  if (experimentalLayout) {
    return <ExperimentalProjectCheckoutAudienceContainer />;
  }

  return <ProjectCheckoutAudienceContainer projectType={projectType} />;
}

export interface ProjectCheckoutAudiencePageProps {
  projectType: 'BIOGRAPHY' | 'BABYBOOK';
}

export function ProjectCheckoutAudiencePage({ projectType }: ProjectCheckoutAudiencePageProps) {
  return (
    <RementoPage type="empty" pageAnalyticsPayload={{ projectType }}>
      <ProjectCheckoutServicesProvider>
        <InternalProjectCheckoutAudiencePage projectType={projectType} />
      </ProjectCheckoutServicesProvider>
    </RementoPage>
  );
}
