import { PropsWithChildren, ReactNode, useLayoutEffect, useRef } from 'react';
import { faGift } from '@fortawesome/pro-solid-svg-icons';
import { PersonName } from '@remento/types/person';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';

import { ProjectCheckoutCurrentStep } from '../../project-checkout.manager.js';
import { ProjectCheckoutLoginLink } from '../ProjectCheckoutLoginLink/ProjectCheckoutLoginLink.js';
import { ProjectCheckoutLogoutLink } from '../ProjectCheckoutLogoutLink/ProjectCheckoutLogoutLink.js';

import {
  Divider,
  Header,
  Left,
  LeftForm,
  LeftFormButton,
  Main,
  MainContent,
  Right,
  StepperWrapper,
  StyledProjectCheckoutDesktopLayout,
  Wordmark,
} from './ProjectCheckoutDesktopLayout.styles.js';

export interface SignedInUser {
  name: PersonName | null;
  email: string;
}

type ProjectCheckoutDesktopLayoutProps = PropsWithChildren<{
  Stepper: ReactNode;
  RightPanel: ReactNode;
  ContinueButton: ReactNode;
  currentStep: ProjectCheckoutCurrentStep;
  user: SignedInUser | null;
  showPurchaseGiftCard?: boolean;
  onSignIn: () => void;
  onSignOut: (redirect?: boolean) => Promise<void>;
  onPurchaseGiftCard?: () => void;
}>;

export function ProjectCheckoutDesktopLayout({
  Stepper,
  RightPanel,
  ContinueButton,
  currentStep,
  user,
  showPurchaseGiftCard = true,
  onSignIn,
  onSignOut,
  onPurchaseGiftCard,
  children,
}: ProjectCheckoutDesktopLayoutProps) {
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollAreaRef.current == null) {
      return;
    }
    scrollAreaRef.current.scrollTop = 0;
  }, [currentStep]);

  return (
    <StyledProjectCheckoutDesktopLayout>
      <Header data-center={showPurchaseGiftCard == false}>
        <Wordmark destination="marketing" />
        {showPurchaseGiftCard && (
          <RMButton leftIcon={faGift} background="outlined" onClick={onPurchaseGiftCard}>
            Purchase gift card
          </RMButton>
        )}
      </Header>
      <Divider />
      <Main ref={scrollAreaRef}>
        <MainContent>
          <Left>
            <LeftForm>
              <StepperWrapper role="navigation">{Stepper}</StepperWrapper>
              <RMSpacer spacing="3xl" direction="column" />
              {children}
              <RMSpacer spacing="2xl" direction="column" />
              <LeftFormButton role="group">{ContinueButton}</LeftFormButton>
              {user ? (
                <>
                  <RMSpacer spacing="xl" direction="column" />
                  <ProjectCheckoutLogoutLink onSignOut={onSignOut} user={user} />
                </>
              ) : (
                <>
                  <RMSpacer spacing="xl" direction="column" />
                  <ProjectCheckoutLoginLink onClick={onSignIn} />
                </>
              )}
            </LeftForm>
          </Left>
          <Right>{RightPanel}</Right>
        </MainContent>
      </Main>
    </StyledProjectCheckoutDesktopLayout>
  );
}
