import { useMemo } from 'react';
import dayjs from 'dayjs';

import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox.js';
import { RMDateInput } from '@/components/RMDateInput/RMDateInput';
import { RMInput } from '@/components/RMInput';
import { RMPhoneInput } from '@/components/RMPhoneInput/RMPhoneInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';

import { ProjectCheckoutForm } from '../../project-checkout.form.js';

import { Container, Field, FormGrid, InputGrid, SubHeader } from './BiographyProjectCheckoutDeliveryForm.styles.js';

export interface BiographyProjectCheckoutDeliveryFormProps {
  form: ProjectCheckoutForm;
  timezone: string;
  showPrintableCardOption: boolean;
}

export function BiographyProjectCheckoutDeliveryForm({
  form,
  timezone,
  showPrintableCardOption,
}: BiographyProjectCheckoutDeliveryFormProps) {
  const isMobile = useIsMobileViewport();

  const minDatePickerDate = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return dayjs(today).format('YYYY-MM-DD');
  }, []);

  return (
    <Container>
      <RMText as="h1" type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Gift delivery details
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <FormGrid>
        <Field size="large">
          <InputGrid>
            <InputContainer form={form} path="recipient.person.firstName">
              {(props) => (
                <Field size="small">
                  <RMInput
                    id="storyteller-first-name"
                    label={isMobile ? 'Your gift recipient’s first name' : 'Your gift recipient’s name'}
                    aria-label="Your gift recipient’s first name"
                    placeholder="Their first name"
                    {...props}
                  />
                </Field>
              )}
            </InputContainer>
            <InputContainer form={form} path="recipient.person.lastName">
              {(props) => (
                <Field size="small">
                  <RMInput
                    id="storyteller-last-name"
                    // \u00A0 === &nbsp;
                    // We need this to keep both inputs at the same position.
                    label={isMobile ? 'Your gift recipient’s last name' : '\u00A0'}
                    aria-label="Your gift recipient’s last name"
                    placeholder="Their last name"
                    {...props}
                  />
                </Field>
              )}
            </InputContainer>
          </InputGrid>
          <RMSpacer spacing="xs" direction="column" />
          <RMText size="xs" type="sans" color="on-surface-tertiary">
            This is the person receiving prompts and recording stories.
          </RMText>
        </Field>
        <InputContainer form={form} path="recipient.email">
          {(props) => (
            <Field size="large">
              <RMInput
                id="storyteller-email"
                label="Your gift recipient’s email"
                placeholder="first.last@example.com"
                autoCapitalize="none"
                type="email"
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputController form={form} path="recipient.phone">
          {(props) => (
            <Field size="large">
              <RMPhoneInput label="Your gift recipient’s phone number (optional)" {...props} />
              <RMSpacer spacing="xs" direction="column" />
              <RMText size="xs" type="sans" color="on-surface-tertiary">
                Remento sends helpful reminders via text (currently US numbers only). Text STOP or disable in settings
                to unsubscribe. Msg & data rates may apply.
              </RMText>
            </Field>
          )}
        </InputController>
        <InputController form={form} path="gift.sendOn" immediate>
          {(props) => (
            <Field size="large">
              <RMDateInput
                id="storyteller-start-date"
                type="date"
                label="Email my gift on"
                placeholder="XX/XX/XXXX"
                min={minDatePickerDate}
                defaultTime="06:00:00"
                {...props}
              />
              <RMSpacer spacing="xs" direction="column" />
              <RMText size="xs" type="sans" color="on-surface-tertiary">
                All gift messages are sent at 6:00 AM {timezone} on the date selected.
              </RMText>
            </Field>
          )}
        </InputController>

        <SubHeader type="serif" size={isMobile ? 'm' : 'l'} color="on-surface-primary">
          Add a gift message
        </SubHeader>

        <InputContainer form={form} path="gift.from">
          {(props) => (
            <Field size="large">
              <RMInput id="gift-from" label="From" placeholder="Your name + anyone else the gift is from" {...props} />
            </Field>
          )}
        </InputContainer>

        <InputContainer form={form} path="gift.message">
          {(props) => (
            <Field size="large">
              <RMInput id="gift-message" label="Gift message" textarea {...props} />
            </Field>
          )}
        </InputContainer>
      </FormGrid>
      <RMSpacer spacing="md" direction="column" />
      <RMText type="sans" size="xs" color="on-surface-tertiary">
        Note: You’ll be able to edit this information until the send date.
      </RMText>

      {showPrintableCardOption && (
        <>
          <RMSpacer spacing="md" direction="column" />
          <InputController form={form} path="gift.sendPrintableCard">
            {(props) => (
              <Field size="large">
                <RMCheckbox
                  id="storyteller-printable-card"
                  label="Email me a printable card, too (great for gifting in-person)"
                  labelSize="s"
                  labelColor="on-surface-secondary"
                  square
                  bold={false}
                  checked={props.value ?? false}
                  onChange={(newValue) => {
                    props.onChange(newValue);
                    props.onBlur();
                  }}
                />
              </Field>
            )}
          </InputController>
        </>
      )}
    </Container>
  );
}
