import { PersonName } from '@remento/types/person';

import { RMText } from '@/components/RMText/RMText';

import { LogoutLink } from './ProjectCheckoutLogoutLink.styles';

export interface SignedInUser {
  name: PersonName | null;
  email: string;
}

export interface ProjectCheckoutLogoutLinkProps {
  user: SignedInUser;
  onSignOut: (redirect?: boolean) => Promise<void>;
}

export function ProjectCheckoutLogoutLink({ user, onSignOut }: ProjectCheckoutLogoutLinkProps) {
  return (
    <div role="region" ria-label="Login information">
      <RMText type="sans" size="xs" color="on-surface-primary">
        Not{' '}
        <RMText type="sans" bold size="xs" color="on-surface-primary">
          {user.name?.first} ({user.email})
        </RMText>
        ?{' '}
        <LogoutLink onClick={() => onSignOut(false)} role="link" aria-label="Logout here">
          Log out
        </LogoutLink>
        .
      </RMText>
    </div>
  );
}
