import { ReactNode, useMemo } from 'react';
import { notNull } from '@remento/utils/array/notNull';
import dayjs from 'dayjs';

import { useInputValue } from '@/modules/form/input';
import { formatPhoneNumber } from '@/utils/phone-number';

import { ProjectCheckoutForm } from '../../project-checkout.form.js';
import { useIsExperimentalProjectCheckoutLayoutEnabled } from '../../project-checkout.utils.js';
import { ProjectCheckoutReview } from '../ProjectCheckoutReview/ProjectCheckoutReview.js';

export interface AutobiographyProjectCheckoutReviewProps {
  form: ProjectCheckoutForm;
  onEditHostInfo: () => void;
  onEditAddonsInfo: () => void;
  CheckoutSummary: ReactNode;
}

export function AutobiographyProjectCheckoutReview({
  form,
  onEditHostInfo,
  onEditAddonsInfo,
  CheckoutSummary,
}: AutobiographyProjectCheckoutReviewProps) {
  const rawOwnerPhone = useInputValue(form, 'owner.phone');
  const ownerPhone = useMemo(() => formatPhoneNumber(rawOwnerPhone), [rawOwnerPhone]);

  const ownerFirstName = useInputValue(form, 'owner.person.firstName');
  const ownerLastName = useInputValue(form, 'owner.person.lastName');
  const ownerEmail = useInputValue(form, 'owner.email');
  const books = useInputValue(form, 'addons.books');
  const ebook = useInputValue(form, 'addons.ebook');

  const legacyboxQuantity = useInputValue(form, 'addons.legacybox.quantity');
  const legacyboxShipDate = useInputValue(form, 'addons.legacybox.shipDate');
  const legacyboxRecipientName = useInputValue(form, 'addons.legacybox.recipientName');
  const legacyboxAddressLine1 = useInputValue(form, 'addons.legacybox.addressLine1');
  const legacyboxAddressLine2 = useInputValue(form, 'addons.legacybox.addressLine2');
  const legacyboxCity = useInputValue(form, 'addons.legacybox.city');
  const legacyboxState = useInputValue(form, 'addons.legacybox.state');
  const legacyboxZipCode = useInputValue(form, 'addons.legacybox.zipCode');

  // AB Testing
  const experimentalLayout = useIsExperimentalProjectCheckoutLayoutEnabled('AUTOBIOGRAPHY');

  return (
    <ProjectCheckoutReview
      title="Review your order"
      CheckoutSummary={CheckoutSummary}
      sections={[
        {
          title: 'Purchase details',
          onEdit: onEditHostInfo,
          items: [
            { label: 'Your name', value: `${ownerFirstName} ${ownerLastName}`, bold: !experimentalLayout },
            { label: 'Your email', value: ownerEmail },
            ownerPhone != null ? { label: 'Your phone number', value: ownerPhone, bold: !experimentalLayout } : null,
            experimentalLayout ? null : { label: 'Number of books', value: books, bold: !experimentalLayout },
            experimentalLayout ? null : { label: 'Add E-book', value: ebook ? 'Yes' : 'No', bold: !experimentalLayout },
          ].filter(notNull),
        },
        legacyboxQuantity != null && legacyboxQuantity > 0
          ? {
              title: 'Photo digitization service',
              onEdit: onEditAddonsInfo,
              items: [
                { label: 'Package selected', value: `${legacyboxQuantity} photos`, bold: !experimentalLayout },
                {
                  label: 'Shipping date',
                  value: dayjs(legacyboxShipDate).format('MMM D, YYYY'),
                  bold: !experimentalLayout,
                },
                { label: 'Recipient', value: legacyboxRecipientName, bold: !experimentalLayout },
                {
                  label: "Recipient's address",
                  value: [
                    '',
                    legacyboxAddressLine1,
                    legacyboxAddressLine2 != null && legacyboxAddressLine2.length > 0 ? legacyboxAddressLine2 : null,
                    `${legacyboxCity}, ${legacyboxState} ${legacyboxZipCode}`,
                  ]
                    .filter(notNull)
                    .join('\n'),
                  bold: !experimentalLayout,
                },
              ],
            }
          : null,
      ].filter(notNull)}
    />
  );
}
