import { useCallback, useMemo } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { createLogger } from '@remento/logger';

import BundleBannerSrc from '@/assets/checkout-bundle-banner.webp';
import BannerSrc from '@/assets/signin.png';
import BabyBannerSrc from '@/assets/signin-babybook.png';
import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { removeToast, toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import {
  ProjectCheckoutServicesProvider,
  useProjectCheckoutServices,
} from '@/modules/project-checkout/project-checkout-services.context.';
import {
  getCheckoutAudiencePath,
  getCheckoutGiftPath,
  getSetupPromptTypeSelectionPath,
  RementoPage,
} from '@/modules/routing';
import { RoutePath } from '@/modules/routing/types/routing.types';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useCheckoutSession } from '@/services/api/checkout/checkout.service.hooks';
import { captureException } from '@/utils/captureException';

import {
  Body,
  Card,
  ContactWrapper,
  Container,
  Divider,
  Footer,
  Header,
  Link,
  ScrollArea,
  Wordmark,
} from './ProjectCheckoutGetStartedPage.styles.js';

const logger = createLogger('checkout-get-started-page');

function InternalProjectCheckoutGetStartedPage() {
  const isMobile = useIsMobileViewport();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { intercomService, authService, checkoutAnalyticsService, redirectService, browserAnalytics } = useServices();
  const { projectCheckoutRecoveryRepository } = useProjectCheckoutServices();
  const user = useUser();

  const latestCheckout = useMemo(
    () => projectCheckoutRecoveryRepository.getLatestCheckout(),
    [projectCheckoutRecoveryRepository],
  );
  const checkoutSessionId =
    searchParams.get('checkout-session-id') ??
    searchParams.get('checkoutSessionId') ??
    latestCheckout?.checkoutId ??
    null;
  const checkoutSession = useCheckoutSession(checkoutSessionId);
  const projectId = searchParams.get('projectId');
  const checkoutType = (searchParams.get('checkout-type') ?? searchParams.get('projectType')) as
    | 'BIOGRAPHY'
    | 'AUTOBIOGRAPHY'
    | 'BABYBOOK'
    | 'FREE'
    | 'BUNDLE'
    | 'GIFT-CARD';
  const freeProjectType = searchParams.get('free-project-type') as 'BIOGRAPHY' | 'AUTOBIOGRAPHY' | null;

  const handleGetStarted = useCallback(async () => {
    if (checkoutType === 'BUNDLE') {
      if (user != null) {
        navigate(getCheckoutAudiencePath('biography'));
        return;
      }
    } else if (checkoutType === 'GIFT-CARD') {
      navigate(getCheckoutGiftPath());
      return;
    } else {
      if (user != null && projectId == null) {
        // User already signed in, no need to send sign in email link
        navigate(RoutePath.OnboardingIntro + '?' + searchParams.toString());
        return;
      } else if (user != null && projectId != null) {
        navigate(getSetupPromptTypeSelectionPath(projectId));
        return;
      }
    }

    if (!checkoutSession.data) {
      return;
    }
    const email = checkoutSession.data.buyerEmail;
    const submittingToastId = toast('Sending sign in link...', 'root-toast', 'default');

    try {
      checkoutAnalyticsService.onCheckoutGetStarted();
      if (checkoutType === 'BUNDLE') {
        await redirectService.registerRedirect('signed-in', getCheckoutAudiencePath('biography'));
      } else if (projectId != null) {
        await redirectService.registerRedirect('signed-in', getSetupPromptTypeSelectionPath(projectId));
      }
      await authService.sendEmailSignInLink(email, true);
      await browserAnalytics
        .identify({ type: 'email', value: email }, 'book-sign-in-request', { email })
        .catch((error) => logger.error('IDENTIFY_FAILED', () => ({ error })));
      toast('Email sign in link sent!', 'root-toast', 'default');
      navigate(RoutePath.OnboardingSignin + '?' + searchParams.toString());
    } catch (error) {
      captureException(error, true);
      toast('Failed to send email sign in link', 'root-toast', 'error');
    } finally {
      removeToast(submittingToastId);
    }
  }, [
    checkoutType,
    checkoutSession.data,
    user,
    navigate,
    projectId,
    searchParams,
    checkoutAnalyticsService,
    authService,
    browserAnalytics,
    redirectService,
  ]);

  const { continueButtonLabel, headerText, subheaderText } = useMemo(() => {
    const defaultSubheaderText =
      'Select your first prompt from our list of pre-written questions, or add a photo to make the experience as unique as your stories.';

    switch (checkoutType) {
      case 'BABYBOOK': {
        return {
          continueButtonLabel: "Let's go!",
          headerText: 'Get started with your first story',
          subheaderText: 'Try it out by adding your first photo and sharing your story. You can always edit later!',
        };
      }
      case 'AUTOBIOGRAPHY': {
        return {
          continueButtonLabel: 'Pick your first prompt (2min)',
          headerText: 'Pick your first prompt',
          subheaderText: defaultSubheaderText,
        };
      }
      case 'BIOGRAPHY': {
        return {
          continueButtonLabel: 'Pick their first prompt (2min)',
          headerText: 'Pick their first prompt',
          subheaderText: defaultSubheaderText,
        };
      }
      case 'FREE': {
        return {
          continueButtonLabel: 'Pick first prompt (2min)',
          headerText: freeProjectType === 'AUTOBIOGRAPHY' ? 'Pick your first prompt' : 'Pick their first prompt',
          subheaderText: defaultSubheaderText,
        };
      }
      case 'BUNDLE': {
        return {
          continueButtonLabel: user != null ? 'Schedule gift delivery' : 'Login to schedule gift delivery',
          headerText: 'It’s time to set up your first gift delivery',
          subheaderText:
            'Get started by personalizing the gift message and send date of each gift. We have emailed you the instructions if you would like to do this later.',
        };
      }
      case 'GIFT-CARD': {
        return {
          continueButtonLabel: 'Purchase another gift card',
          headerText: 'We’ll email you your gift certificate',
          subheaderText:
            'We’ll send you an email with your gift certificate in the next 5 minutes. Either print it out, or simply email the attachment to the recipient.',
        };
      }
    }
  }, [checkoutType, freeProjectType, user]);

  const bannerImage = useMemo(() => {
    switch (checkoutType) {
      case 'BABYBOOK': {
        return BabyBannerSrc;
      }
      case 'BUNDLE': {
        return BundleBannerSrc;
      }
      default: {
        return BannerSrc;
      }
    }
  }, [checkoutType]);

  // Redirect to the start of the flow if no checkout session id
  // is present or if the id is invalid
  if (checkoutSessionId === null) {
    return <Navigate to="/checkout" />;
  }
  if (checkoutSession.error) {
    return <Navigate to="/checkout" />;
  }

  if (!checkoutSession.data) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Header isMobile={isMobile}>
        <Wordmark />
      </Header>
      <Divider />

      <ScrollArea>
        <Body data-mobile={isMobile}>
          <RMText as="h1" type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
            {checkoutType === 'FREE' || checkoutSession.data.paid == 0
              ? 'It’s time to set up your new project!'
              : 'Thanks for your purchase!'}
          </RMText>
          {checkoutSession.data.paid > 0 && (
            <>
              <RMSpacer spacing="lg" direction="column" />
              <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-tertiary">
                Order number #<b>{checkoutSession.data.invoiceNumber ?? 'N/A'}</b>
              </RMText>
            </>
          )}
          <Card>
            <img src={bannerImage} alt="Product photo" aria-hidden="true" />
            <RMSpacer spacing="md" direction="column" />
            <RMText as="h2" type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
              {headerText}
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <RMText type="sans" size="s" color="on-surface-primary" lineHeight="s">
              {subheaderText}
            </RMText>
            {isMobile === false && (
              <>
                <RMSpacer spacing="xl" direction="column" />
                <RMButton background="primary" onClick={handleGetStarted} fullWidth autoLoading>
                  {continueButtonLabel}
                </RMButton>
              </>
            )}
          </Card>
          {checkoutType != 'BABYBOOK' && checkoutType != 'BUNDLE' && checkoutType != 'GIFT-CARD' && (
            <div role="note">
              <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
              <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary" lineHeight="s">
                <b>Ready to buy again?</b> Purchase Remento for another storyteller once you've added your first prompt.
              </RMText>
            </div>
          )}
          <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
          <ContactWrapper>
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
              Questions?
            </RMText>
            <button onClick={() => intercomService?.setChatDisplayed(true)}>
              <RMText type="sans" size={isMobile ? 'xs' : 's'} underline>
                <Link>Contact Support</Link>
              </RMText>
            </button>
          </ContactWrapper>
        </Body>
      </ScrollArea>

      {isMobile && (
        <Footer>
          <RMButton background="primary" onClick={handleGetStarted} fullWidth autoLoading>
            {continueButtonLabel}
          </RMButton>
        </Footer>
      )}
    </Container>
  );
}

export function ProjectCheckoutGetStartedPage() {
  return (
    <RementoPage type="empty">
      <ProjectCheckoutServicesProvider>
        <InternalProjectCheckoutGetStartedPage />
      </ProjectCheckoutServicesProvider>
    </RementoPage>
  );
}
