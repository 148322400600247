import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { Container, Image } from './ProjectCheckoutSummary.desktop.styles';
import { ProjectCheckoutSummaryProps } from './ProjectCheckoutSummary.types';
import { getProjectCheckoutSummaryImage, getProjectCheckoutSummaryPreloadImages } from './ProjectCheckoutSummary.utils';

export function ProjectCheckoutSummaryDesktop({ type, title, total, includes }: ProjectCheckoutSummaryProps) {
  const image = getProjectCheckoutSummaryImage(type, total.products);
  const preloadImages = getProjectCheckoutSummaryPreloadImages(type);

  return (
    <Container>
      {/* Preload the image to prevent animation issues */}
      {preloadImages.map((src) => (
        <img key={src} src={src} style={{ display: 'none' }} />
      ))}

      <Image src={image.src} data-portrait={image.portrait} alt="Book" aria-hidden="true" />
      <RMText type="serif" size="xl" color="on-surface-primary">
        {title}
      </RMText>
      <RMSpacer spacing="sm" direction="column" />
      <RMStack spacing="sm" direction="row" center>
        <RMText type="serif" size="l" color="on-surface-primary">
          Total:{' '}
          {total.status === 'fetching' ? (
            <RMText type="serif" size="l" color="on-surface-tertiary">
              Loading...
            </RMText>
          ) : (
            <b>${total.price?.toFixed(2).replace(/[.,]00$/, '')}</b>
          )}
        </RMText>

        {total.status === 'fetched' && total.price != total.officialPrice && (
          <RMText
            type="serif"
            size="l"
            color="on-surface-tertiary"
            style={{ textDecoration: 'line-through', alignSelf: 'self-end' }}
            aria-hidden="true"
          >
            ${total.officialPrice.toFixed(2).replace(/[.,]00$/, '')}
          </RMText>
        )}
      </RMStack>
      <RMSpacer spacing="2xl" direction="column" />
      <RMText
        type="sans"
        size="xxs"
        bold
        color="on-surface-secondary"
        trackedOutCaps
        id="checkout-summary-includes-label"
      >
        INCLUDES
      </RMText>
      <RMSpacer spacing="xl" direction="column" />
      <ul aria-labelledby="checkout-summary-includes-label">
        {includes.map((productInclude) => (
          <li key={productInclude.message}>
            <RMStack spacing="xs" direction="row" center>
              <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
              <RMText type="sans" size="s" color="on-surface-primary">
                {productInclude.message}
              </RMText>
              {productInclude.tooltip != null && (
                <RMTooltip showOnTouch message={productInclude.tooltip}>
                  <FontAwesomeIcon icon={faCircleExclamation} size="sm" color={ColorsTokens.primary} />
                </RMTooltip>
              )}
            </RMStack>
            <RMSpacer spacing="xs" direction="column" />
          </li>
        ))}
      </ul>
    </Container>
  );
}
