import { useState } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

import { RMText } from '@/components/RMText/RMText.js';

import { ExperimentalProjectCheckoutSummary } from '../ExperimentalProjectCheckoutSummary/ExperimentalProjectCheckoutSummary.js';
import { ExperimentalProjectCheckoutSummaryProps } from '../ExperimentalProjectCheckoutSummary/ExperimentalProjectCheckoutSummary.types.js';

import {
  Absolute,
  AccordionRoot,
  Header,
  HeaderContent,
} from './ExperimentalProjectCheckoutSummaryAccordion.styles.js';

export function ExperimentalProjectCheckoutSummaryAccordion({ total, items }: ExperimentalProjectCheckoutSummaryProps) {
  const [isOpen, setOpen] = useState(false);

  return (
    <AccordionRoot aria-expanded={isOpen} aria-label="Order summary">
      <Header onClick={() => setOpen((previous) => !previous)}>
        <HeaderContent open={isOpen}>
          <FontAwesomeIcon icon={faChevronRight} color="var(--on-surface-primary)" size="sm" />
          <RMText type="serif" size="m" color="on-surface-primary">
            Order summary
          </RMText>
        </HeaderContent>

        <RMText
          type="serif"
          size="m"
          bold={total != null}
          color={total != null ? 'on-surface-primary' : 'on-surface-tertiary'}
        >
          {total != null ? '$' + total : 'Loading...'}
        </RMText>
      </Header>

      <Absolute>
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: isOpen ? 'auto' : 0 }}
          style={{ overflow: 'hidden', borderBottom: isOpen ? '2px solid #b7c6c3' : 0 }}
        >
          <ExperimentalProjectCheckoutSummary total={total} items={items} />
        </motion.div>
      </Absolute>
    </AccordionRoot>
  );
}
