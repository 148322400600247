import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledProjectCheckoutMobileLayout = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

export const ScrollArea = styled.div`
  height: 100%;
  overflow: auto;
`;

export const Wordmark = styled(RMWordmark)`
  margin: var(--spacing-lg) var(--spacing-md);
  height: 1rem;
  align-self: self-start;
`;

export const Main = styled.main`
  padding: var(--spacing-md);

  body[data-mobile='true'] & {
    position: relative;
    min-height: 100%;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  background-color: var(--surface);
  border-top: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-top-2);
`;
