import { faGift } from '@fortawesome/pro-solid-svg-icons';
import { PersonName } from '@remento/types/person';

import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { ProjectCheckoutLoginLink } from '../ProjectCheckoutLoginLink/ProjectCheckoutLoginLink.js';
import { ProjectCheckoutLogoutLink } from '../ProjectCheckoutLogoutLink/ProjectCheckoutLogoutLink.js';

import { Container, Form, PurchaseButtonMobile } from './ProjectCheckoutAudienceForm.styles.js';

// eslint-disable-next-line react-refresh/only-export-components
export enum AudienceTarget {
  SomeoneElse = 'someone-else',
  Myself = 'myself',
}

export interface AudienceOption {
  label: string;
  value: AudienceTarget;
  disabled?: boolean;
}

export interface SignedInUser {
  name: PersonName | null;
  email: string;
}

export interface ProjectCheckoutAudienceFormProps {
  user?: SignedInUser | null;
  title: string;
  audienceOptions: AudienceOption[];
  selectedValue: AudienceTarget;
  onSignIn?: () => void;
  onSignOut?: (redirect?: boolean) => Promise<void>;
  onSelectedChange: (selected: AudienceTarget) => void;
  onPurchaseGiftCard: (() => void) | null;
}

export function ProjectCheckoutAudienceForm({
  user,
  title,
  audienceOptions,
  selectedValue,
  onSignIn,
  onSignOut,
  onSelectedChange,
  onPurchaseGiftCard,
}: ProjectCheckoutAudienceFormProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container>
      <RMText
        as="h1"
        type="serif"
        size={isMobile ? 'l' : 'xl'}
        color="on-surface-primary"
        style={{ alignSelf: 'start' }}
      >
        {title}
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <Form>
        <RMPicker.Root>
          {audienceOptions.map((audienceOption) => (
            <RMPicker.Item
              key={audienceOption.value}
              label={audienceOption.label}
              value={audienceOption.value}
              selected={selectedValue === audienceOption.value}
              onClick={() => onSelectedChange(audienceOption.value)}
              disabled={audienceOption.disabled}
            />
          ))}
        </RMPicker.Root>
      </Form>
      {/* TODO: We can remove this if we decide to use only the new layout */}
      {isMobile && onSignOut != null && onSignIn && (
        <>
          <RMSpacer spacing="xl" direction="column" />
          {user ? (
            <ProjectCheckoutLogoutLink onSignOut={onSignOut} user={user} />
          ) : (
            <ProjectCheckoutLoginLink onClick={onSignIn} />
          )}
        </>
      )}

      {isMobile && onPurchaseGiftCard != null && (
        <PurchaseButtonMobile leftIcon={faGift} background="none" onClick={onPurchaseGiftCard}>
          Purchase gift card
        </PurchaseButtonMobile>
      )}
    </Container>
  );
}
