import { useEffect, useState } from 'react';

import { BiographyProjectCheckoutAddonsForm } from '../components/BiographyProjectCheckoutAddonsForm/BiographyProjectCheckoutAddonsForm.js';
import { LegacyboxLearnMoreDialog } from '../components/LegacyboxLearnMoreDialog/LegacyboxLearnMoreDialog.js';
import { useProjectCheckoutManager } from '../project-checkout.manager.context.js';
import { goToStep } from '../project-checkout.manager.js';
import { useIsExperimentalProjectCheckoutLayoutEnabled } from '../project-checkout.utils.js';

export function BiographyProjectCheckoutAddonsStepContainer() {
  const manager = useProjectCheckoutManager();
  const [legacyboxLearnMoreOpen, setLegacyboxLearnMoreOpen] = useState(false);

  // AB Testing
  const experimentalLayout = useIsExperimentalProjectCheckoutLayoutEnabled('BIOGRAPHY');

  useEffect(() => {
    goToStep(manager, 'addons');
  }, [manager]);

  return (
    <>
      <BiographyProjectCheckoutAddonsForm
        form={manager.form}
        experimentalCopy={experimentalLayout}
        onLegacyBoxLearnMore={() => {
          setLegacyboxLearnMoreOpen(true);
        }}
      />
      {legacyboxLearnMoreOpen && (
        <LegacyboxLearnMoreDialog
          onClose={() => {
            setLegacyboxLearnMoreOpen(false);
          }}
        />
      )}
    </>
  );
}
