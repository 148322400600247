import { useState } from 'react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { Absolute, Content, Header, HeaderContent, Image, Summary } from './ProjectCheckoutSummary.mobile.styles';
import { ProjectCheckoutSummaryProps } from './ProjectCheckoutSummary.types';
import { getProjectCheckoutSummaryImage } from './ProjectCheckoutSummary.utils';

export function ProjectCheckoutSummaryMobile({ type, title, total, includes }: ProjectCheckoutSummaryProps) {
  const [isOpen, setOpen] = useState(false);
  const image = getProjectCheckoutSummaryImage(type, total.products);

  return (
    <Summary aria-expanded={isOpen} aria-label="Order summary">
      <Header onClick={() => setOpen((previous) => !previous)}>
        <HeaderContent open={isOpen}>
          <FontAwesomeIcon icon={faChevronRight} color="var(--on-surface-primary)" size="sm" />
          <RMText type="serif" size="m" color="on-surface-primary">
            {title}
          </RMText>
        </HeaderContent>

        <RMText type="serif" size="m" bold={total.status === 'fetched'} color="on-surface-primary">
          {total.status === 'fetching' ? 'Loading...' : `$${total.price}`}
        </RMText>
      </Header>

      <Absolute>
        <motion.div initial={{ height: 0 }} animate={{ height: isOpen ? 'auto' : 0 }} style={{ overflow: 'hidden' }}>
          <Content>
            <Image src={image.src} data-portrait={image.portrait} alt="Book" aria-hidden="true" />
            <RMSpacer spacing="xl" direction="column" />
            <RMText
              type="sans"
              size="xxs"
              bold
              color="on-surface-primary"
              trackedOutCaps
              id="checkout-summary-includes-label"
            >
              INCLUDES
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <ul aria-labelledby="checkout-summary-includes-label">
              {includes.map((productInclude) => (
                <li key={productInclude.message}>
                  <RMStack spacing="xs" direction="row" center>
                    <FontAwesomeIcon icon={faCheck} fontSize={14} color="var(--on-surface-primary)" />
                    <RMText type="sans" size="s" color="on-surface-primary">
                      {productInclude.message}
                    </RMText>
                    {productInclude.tooltip != null && (
                      <RMTooltip showOnTouch message={productInclude.tooltip} side="bottom">
                        <FontAwesomeIcon icon={faCircleExclamation} size="sm" color={ColorsTokens.primary} />
                      </RMTooltip>
                    )}
                  </RMStack>
                  <RMSpacer spacing="2xs" direction="column" />
                </li>
              ))}
            </ul>
          </Content>
        </motion.div>
      </Absolute>
    </Summary>
  );
}
