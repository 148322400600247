import { ReactNode } from 'react';

export interface RMSelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface RMSelectProps {
  id: string;
  label?: ReactNode;
  boldLabel?: boolean;
  ariaLabel?: string;
  value?: RMSelectOption['value'] | null;
  error?: string;
  disabled?: boolean;
  options: RMSelectOption[];
  onChange: (value: RMSelectOption['value']) => void;
}

export const SELECT_EMPTY_VALUE = '@@empty@@';
