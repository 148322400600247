import { styled } from '@linaria/react';

export const LogoutLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: #446969;
  transition: color 100ms ease-in-out;

  :hover {
    color: var(--on-surface-secondary);
  }
`;
