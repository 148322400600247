import { ReactNode, useMemo } from 'react';
import { notNull } from '@remento/utils/array/notNull';

import { useInputValue } from '@/modules/form/input';
import { formatPhoneNumber } from '@/utils/phone-number';

import { ProjectCheckoutForm } from '../../project-checkout.form.js';
import { ProjectCheckoutReview } from '../ProjectCheckoutReview/ProjectCheckoutReview.js';

export interface BabybookProjectCheckoutReviewProps {
  form: ProjectCheckoutForm;
  onEditHostInfo: () => void;
  onEditProjectName: () => void;
  CheckoutSummary: ReactNode;
}

export function BabybookProjectCheckoutReview({
  form,
  onEditHostInfo,
  onEditProjectName,
  CheckoutSummary,
}: BabybookProjectCheckoutReviewProps) {
  const rawOwnerPhone = useInputValue(form, 'owner.phone');
  const ownerPhone = useMemo(() => formatPhoneNumber(rawOwnerPhone), [rawOwnerPhone]);

  const subjectFirstName = useInputValue(form, 'subject.firstName');
  const projectName = useInputValue(form, 'name');
  const ownerFirstName = useInputValue(form, 'owner.person.firstName');
  const ownerLastName = useInputValue(form, 'owner.person.lastName');
  const ownerEmail = useInputValue(form, 'owner.email');
  const books = useInputValue(form, 'addons.books');
  const ebook = useInputValue(form, 'addons.ebook');

  return (
    <ProjectCheckoutReview
      title="Review your order"
      CheckoutSummary={CheckoutSummary}
      sections={[
        {
          title: 'Your profile',
          onEdit: onEditHostInfo,
          items: [
            { label: 'Your name', value: `${ownerFirstName} ${ownerLastName}` },
            { label: 'Your email', value: ownerEmail },
            ownerPhone != null ? { label: 'Your phone number', value: ownerPhone } : null,
            subjectFirstName != null && subjectFirstName.length > 0
              ? { label: "Your baby's name", value: `${subjectFirstName} ${ownerLastName}` }
              : null,
          ].filter(notNull),
        },
        {
          title: 'Your Book',
          onEdit: onEditProjectName,
          items: [
            { label: 'Book title', value: projectName },
            { label: 'Number of books', value: books },
            { label: 'Add E-book', value: ebook ? 'Yes' : 'No' },
          ].filter(notNull),
        },
      ]}
    />
  );
}
