import { useEffect } from 'react';

import { AutobiographyProjectCheckoutReview } from '../components/AutobiographyProjectCheckoutReview/AutobiographyProjectCheckoutReview.js';
import { useProjectCheckoutManager } from '../project-checkout.manager.context.js';
import { goToStep } from '../project-checkout.manager.js';
import { useIsExperimentalProjectCheckoutLayoutEnabled } from '../project-checkout.utils.js';

import { ExperimentalProjectCheckoutSummaryContainer } from './ExperimentalProjectCheckoutSummary.container.js';
import { ProjectCheckoutSummaryContainer } from './ProjectCheckoutSummary.container.js';

export function AutobiographyProjectCheckoutReviewStepContainer() {
  const manager = useProjectCheckoutManager();

  // AB Testing
  const experimentalLayout = useIsExperimentalProjectCheckoutLayoutEnabled('AUTOBIOGRAPHY');

  useEffect(() => {
    goToStep(manager, 'review');
  }, [manager]);

  return (
    <AutobiographyProjectCheckoutReview
      form={manager.form}
      onEditHostInfo={() => goToStep(manager, 'contact')}
      onEditAddonsInfo={() => goToStep(manager, 'addons')}
      CheckoutSummary={
        experimentalLayout ? (
          <ExperimentalProjectCheckoutSummaryContainer manager={manager} />
        ) : (
          <ProjectCheckoutSummaryContainer manager={manager} sticky />
        )
      }
    />
  );
}
