import { styled } from '@linaria/react';

export const StyledProjectCheckoutReview = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;

  svg {
    color: var(--on-surface-secondary);
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--border-hairline);
  margin: var(--spacing-lg) 0;
`;

export const WarningBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  border-radius: var(--radius-round);
  background-color: var(--surface-bright);
  border: 2px solid var(--caution);
  border-style: solid;
`;
