import { useEffect, useState } from 'react';

import { useServices } from '@/Services';

export function useUserRuid(): string {
  const { browserAnalytics } = useServices();

  const [ruid, setRuid] = useState(() => browserAnalytics.getUser().id);
  useEffect(() => {
    return browserAnalytics.on('identify', (newUser) => {
      setRuid(newUser.id);
    });
  }, [browserAnalytics]);

  return ruid;
}
