import { styled } from '@linaria/react';

export const SummaryRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
  max-width: 600px;

  body[data-mobile='true'] & {
    max-width: unset;
  }
`;

export const SummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-2xl) var(--spacing-3xl);
  width: 100%;
  background-color: var(--surface-bright);
  border-radius: var(--radius-smooth);

  body[data-mobile='true'] & {
    padding: var(--spacing-md);
    background-color: #eff3f2;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2xl);

  body[data-mobile='true'] & {
    gap: var(--spacing-md);
  }
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--spacing-xs);

  body[data-mobile='true'] & {
    gap: 0;
  }
`;

export const CartItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-xs) 0;
`;

export const CartItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const CartItemNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CartItemDescriptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const CartItemDescriptionListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2xs);
`;

export const CartDivider = styled.div`
  height: 1px;
  flex: 0 0 1px;
  background-color: var(--border-weak);
`;

export const PreviewImage = styled.img`
  max-height: 99px;
  object-fit: cover;
  border-radius: 2px;

  body[data-mobile='true'] & {
    max-height: 52px;
    max-width: 52pc;
    border-radius: 4px;
  }
`;

export const NoteCard = styled(SummaryCard)`
  gap: var(--spacing-xs);
`;
