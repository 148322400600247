import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const Header = styled.header<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '0 var(--spacing-lg)' : '0 var(--spacing-3xl)')};
  height: ${(props) => (props.isMobile ? '3.5rem' : '5rem')};
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wordmark = styled(RMWordmark)`
  height: 1.5rem;
  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const ScrollArea = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
`;

export const Body = styled.main`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  max-width: 600px;
  margin: 0 auto;

  &[data-mobile='true'] {
    max-width: 100%;
    padding: var(--spacing-md);
  }
`;

export const Card = styled.div`
  margin-top: var(--spacing-2xl);
  width: 100%;

  > img {
    width: 100%;
    opacity: 0.9;
    border-radius: var(--radius-minimal);
    object-fit: cover;
    object-position: 50% 30%;
    aspect-ratio: 1.7;
    max-width: 500px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 769px) {
    max-width: 510px;
    border-radius: var(--radius-round);
    background-color: var(--surface-bright);
    box-shadow: var(--elevation-bottom-3);
    padding: var(--spacing-xl);
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  gap: var(--spacing-2xs);
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: var(--spacing-2xl);

  body[data-mobile='true'] & {
    justify-content: flex-start;
  }
`;

export const Link = styled.span`
  color: #446969;
  transition: color 100ms ease-in-out;

  :hover {
    color: var(--on-surface-secondary);
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  background-color: var(--surface);
  border-top: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-top-2);
  width: 100%;
`;
