import { useCallback, useEffect, useMemo, useRef } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ShippingAddressCountry } from '@remento/types/shipping';
import { UserPhone } from '@remento/types/user';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { createStore, StoreApi, useStore } from 'zustand';

import { toast } from '@/components/RMToast/RMToast';
import { useSearchParams } from '@/hooks/useSearchParams';
import { logger } from '@/logger';
import { Services, useServices } from '@/Services';
import { isPhoneNumberEmpty } from '@/utils/phone-number';

import { Paths } from '../form/@types-utils.js';
import { isFormValidating, watchFormInputsValue, watchIsFormValidating } from '../form/form.js';
import {
  getInputValue,
  getInputValueOrThrow,
  setInputValue,
  setInputValues,
  useInputValue,
  watchInputValue,
} from '../form/input.js';
import { everyInputValid, watchEveryInputValid } from '../form/input.utils.js';
import { RoutePath } from '../routing/types/routing.types.js';
import { getPathname } from '../routing/utils/location.js';

import { ProjectCheckoutForm, ProjectCheckoutFormInput } from './project-checkout.form.js';
import { ProjectCheckoutServices, useProjectCheckoutServices } from './project-checkout-services.context..js';

/**** MANAGER TYPES ****/

export interface ProjectCheckoutPerson {
  firstName: string;
  lastName: string;
}

export interface ProjectCheckoutUser {
  person: ProjectCheckoutPerson;
  email: string;
  phone?: UserPhone | null;
}

export interface ProjectCheckoutOwner extends ProjectCheckoutUser {
  timezone: string;
}

export interface ProjectCheckoutAddons {
  ebook: boolean;
  books: number;
  /**
   * Legacybox integration
   * This is only used when the project is a `BIOGRAPHY` or `AUTOBIOGRAPHY`.
   */
  legacybox?: {
    quantity: number;
    shipDate: number;
    recipientName: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: ShippingAddressCountry;
  };
}

export interface ProjectCheckoutGift {
  from: string;
  message: string;
  sendOn: number;
  sendPrintableCard: boolean;
}

export type ProjectType = 'BIOGRAPHY' | 'AUTOBIOGRAPHY' | 'BABYBOOK' | 'FREE';

export interface ProjectCheckoutFormData {
  /**
   * The type of the project.
   */
  type: ProjectType;
  /**
   * A stripe coupon to be applied to the order.
   */
  coupon?: string | null;
  /**
   * The ID of the user who made the referral."
   */
  referrerUserId?: string | null;
  /**
   * The owner of the project.
   */
  owner: ProjectCheckoutOwner;
  /**
   * The additional products to be purchased with the project.
   */
  addons: ProjectCheckoutAddons;
  /**
   * The gift information.
   * This is only used when the project is a `BIOGRAPHY`.
   */
  gift?: ProjectCheckoutGift;
  /**
   * The subject of the project. (idk why this is called recipient).
   * This is only used when the project is a `BIOGRAPHY`.
   */
  recipient?: ProjectCheckoutUser;
  /**
   * The name of the project.
   * This is only used when the project is a `BABYBOOK` or `FREE`.
   */
  name?: string;
  /**
   * The subject of the project.
   * This is only used when the project is a `BABYBOOK`.
   */
  subject?: ProjectCheckoutPerson;
  /**
   * The person ID of the recipient of the project.
   * This is only used when the project is a `FREE`.
   */
  recipientPersonId?: string;
  /**
   * The person ID of the owner of the subscription.
   * This is only used when the project is a `FREE`.
   */
  subscriptionOwnerPersonId?: string;
}

export interface ProjectCheckoutStep {
  name: string;
  label: string;
  route: RoutePath;
  inputs: Array<Paths<ProjectCheckoutFormInput>>;
  preStepCallback?: (manager: ProjectCheckoutManager) => Promise<void>;
  postStepCallback?: (manager: ProjectCheckoutManager) => Promise<void | boolean>;
}

export interface ProjectCheckoutCookieCoupon {
  origin: 'cookie';
  cookieName: string;
  coupon: string;
}

export interface ProjectCheckoutQueryParamCoupon {
  origin: 'query-param';
  queryParamName: string;
  coupon: string;
}

export interface ProjectCheckoutUserCoupon {
  origin: 'user';
  coupon: string;
}

export type ProjectCheckoutCoupon =
  | ProjectCheckoutCookieCoupon
  | ProjectCheckoutQueryParamCoupon
  | ProjectCheckoutUserCoupon;

export interface ProjectCheckoutBaseTotal {
  officialPrice: number;
  coupon: string | null;
  products: {
    ebook: boolean;
    books: number;
    legacyboxQuantity: number;
  };
}
export interface ProjectCheckoutFetchingTotal extends ProjectCheckoutBaseTotal {
  status: 'fetching';
}

export interface ProjectCheckoutFetchedTotal extends ProjectCheckoutBaseTotal {
  status: 'fetched';
  price: number;
  isCouponValid: boolean;
  couponName: string | null;
}

export type ProjectCheckoutTotal = ProjectCheckoutFetchingTotal | ProjectCheckoutFetchedTotal;

/**** MANAGER STORE ****/

export interface ProjectCheckoutCurrentStep {
  name: string;
  index: number;
}

export interface ProjectCheckoutManagerState {
  currentStep: ProjectCheckoutCurrentStep;
  validSteps: Record<string, boolean>;
}

export type ProjectCheckoutManagerStore = StoreApi<ProjectCheckoutManagerState>;

/**** MANAGER LOGIC ****/

export interface ProjectCheckoutManager {
  services: Services & ProjectCheckoutServices;
  navigate: NavigateFunction;
  form: ProjectCheckoutForm;
  steps: Array<ProjectCheckoutStep>;
  store: ProjectCheckoutManagerStore;
  destroy: () => void;
}

export function createProjectCheckoutManager(
  services: Services & ProjectCheckoutServices,
  navigate: NavigateFunction,
  form: ProjectCheckoutForm,
  steps: Array<ProjectCheckoutStep>,
): ProjectCheckoutManager {
  const store = createStore<ProjectCheckoutManagerState>(() => ({
    validSteps: {},
    currentStep: {
      index: 0,
      name: steps[0].name,
    },
  }));

  const cleanups: (() => void)[] = [];

  // Restore the form from the local repository.
  // We should not do this for `FREE` projects.
  const type = getInputValue(form, 'type');
  if (type !== 'FREE') {
    const formValue = services.projectCheckoutFormRepository.get();
    if (formValue != null) {
      setInputValues(form, {
        ...formValue,
        // Never restore the type property. It will always be defined.
        type: type ?? undefined,
        // And we should be careful to not override these properties if they
        // are already set to something else
        coupon: getInputValue(form, 'coupon') ?? formValue.coupon,
        owner: {
          person: {
            firstName: getInputValue(form, 'owner.person.firstName') ?? formValue.owner?.person?.firstName,
            lastName: getInputValue(form, 'owner.person.lastName') ?? formValue.owner?.person?.lastName,
          },
          email: getInputValue(form, 'owner.email') ?? formValue.owner?.email,
          phone: getInputValue(form, 'owner.phone') ?? formValue.owner?.phone,
          timezone: getInputValue(form, 'owner.timezone') ?? formValue.owner?.timezone,
        },
        recipientPersonId: getInputValue(form, 'recipientPersonId') ?? formValue.recipientPersonId,
        subscriptionOwnerPersonId:
          getInputValue(form, 'subscriptionOwnerPersonId') ?? formValue.subscriptionOwnerPersonId,
        addons: {
          ...formValue.addons,
          legacybox: {
            ...formValue.addons?.legacybox,
            quantity: type !== 'BIOGRAPHY' && type !== 'AUTOBIOGRAPHY' ? 0 : formValue.addons?.legacybox?.quantity,
          },
        },
      });
    }

    cleanups.push(
      watchFormInputsValue(form, (value) => {
        if (value != null) {
          services.projectCheckoutFormRepository.set(value);
        }
      }),
    );
  }

  // Watch the validation of every form.
  for (const step of steps) {
    if (everyInputValid(form, step.inputs)) {
      store.setState(
        (state) => ({
          ...state,
          validSteps: {
            ...state.validSteps,
            [step.name]: true,
          },
        }),
        true,
      );
    }

    // We should NOT delay the observer.
    // The validSteps state should be always updated in the same tick that the validation
    // is perfumed.
    const cleanup = watchEveryInputValid(form, step.inputs, false, (isValid) => {
      store.setState(
        (state) => ({
          ...state,
          validSteps: {
            ...state.validSteps,
            [step.name]: isValid,
          },
        }),
        true,
      );
    });
    cleanups.push(cleanup);
  }

  // Trigger an a analytics events when the addons of the project changes
  const triggerAddonsChangedEvent = () => {
    services.checkoutAnalyticsService.onCheckoutAddonsChanged(
      getInputValue(form, 'addons.books') ?? 0,
      getInputValue(form, 'addons.ebook') ?? false,
      getInputValue(form, 'addons.legacybox.quantity') ?? 0,
    );
  };

  cleanups.push(watchInputValue(form, 'addons.books', triggerAddonsChangedEvent));
  cleanups.push(watchInputValue(form, 'addons.ebook', triggerAddonsChangedEvent));
  cleanups.push(watchInputValue(form, 'addons.legacybox.quantity', triggerAddonsChangedEvent));

  const destroy = () => {
    for (const cleanup of cleanups) {
      cleanup();
    }
  };

  return { services, navigate, form, steps, store, destroy };
}

const LEGACYBOX_QUANTITY_PRICE_MAP: Record<string, number> = {
  25: 30.98,
  50: 40.98,
  100: 55.98,
  250: 105.98,
  500: 155.98,
  1000: 255.98,
};

// TODO: Refactor this to return a better structure with the price of each product
// if we decide to fully use the new checkout layout
export function useCheckoutTotal(manager: ProjectCheckoutManager): ProjectCheckoutTotal {
  const projectType = useInputValue(manager.form, 'type');
  const books = useInputValue(manager.form, 'addons.books') ?? 0;
  const ebook = useInputValue(manager.form, 'addons.ebook') ?? false;
  const legacyboxQuantity = useInputValue(manager.form, 'addons.legacybox.quantity') ?? 0;
  const rawCoupon = useInputValue(manager.form, 'coupon') ?? null;
  const coupon = rawCoupon != null && rawCoupon.trim().length > 0 ? rawCoupon : null;

  if (projectType == null) {
    // This will never happen.
    throw new Error('Project type cannot be null');
  }

  const priceQuery = useQuery({
    queryKey: ['checkout-price', { projectType, coupon, books, ebook, legacyboxQuantity }],
    queryFn: () =>
      manager.services.checkoutService.getCheckoutTotal({
        projectType,
        coupon,
        books,
        ebook,
        legacyboxQuantity,
      }),
  });

  const officialSubscriptionPrice = projectType === 'FREE' ? 0 : 168;
  const subscriptionPrice = projectType === 'FREE' ? 0 : 99;
  const includedBooks = projectType === 'FREE' ? 0 : 1;
  const legacyboxPrice = LEGACYBOX_QUANTITY_PRICE_MAP[legacyboxQuantity] ?? 0;
  const officialPrice = officialSubscriptionPrice + 69 * (books - includedBooks) + (ebook ? 49.99 : 0) + legacyboxPrice;
  const price = subscriptionPrice + 59 * (books - includedBooks) + (ebook ? 24.99 : 0) + legacyboxPrice;

  if (priceQuery.data == null && coupon != null) {
    return {
      status: 'fetching',
      officialPrice,
      products: { ebook, books, legacyboxQuantity },
      coupon,
    };
  }

  return {
    status: 'fetched',
    price: priceQuery.data?.total ?? price,
    officialPrice,
    products: { ebook, books, legacyboxQuantity },
    coupon,
    couponName: priceQuery.data?.couponName ?? null,
    isCouponValid: priceQuery.data?.isCouponValid ?? true,
  };
}

export function useCreateProjectCheckoutManager(
  form: ProjectCheckoutForm,
  coupons: ProjectCheckoutCoupon[],
  steps: Array<ProjectCheckoutStep>,
): ProjectCheckoutManager {
  const services = useServices();
  const projectCheckoutServices = useProjectCheckoutServices();

  const navigate = useNavigate();

  const manager = useMemo(
    () => createProjectCheckoutManager({ ...services, ...projectCheckoutServices }, navigate, form, steps),
    [form, navigate, projectCheckoutServices, services, steps],
  );

  // Set the initial coupon in the form
  useEffect(() => {
    setInputValue(form, 'coupon', coupons.length ? coupons[0].coupon : '');
  }, [coupons, form]);

  // Show toast if the coupon is invalid.
  // If the user have more than one possible coupon, we should
  // only show the toast when we run of coupons to try.
  // We also should clear the coupon from the cookie/query-param when invalid/
  // const invalidCouponToastShownRef = useRef(false);
  const { setSearchParam } = useSearchParams();
  const total = useCheckoutTotal(manager);

  useEffect(() => {
    if (total.status !== 'fetched' || total.isCouponValid) {
      return;
    }
    const currentCouponValue = getInputValue(manager.form, 'coupon');
    const projectCheckoutCouponIndex = coupons.findIndex((c) => c.coupon === currentCouponValue);
    if (projectCheckoutCouponIndex === -1) {
      // This could happen if we are recovering a coupon from the user local store.
      // We don't need to show a toast in that case.
      if (coupons.length > 0) {
        setInputValue(manager.form, 'coupon', coupons[0].coupon);
        return;
      }
      setInputValue(manager.form, 'coupon', '');
      return;
    }
    const projectCheckoutCoupon = coupons[projectCheckoutCouponIndex];
    if (projectCheckoutCoupon.origin === 'cookie') {
      Cookies.remove(projectCheckoutCoupon.cookieName);
    }
    if (projectCheckoutCoupon.origin === 'query-param') {
      setSearchParam(projectCheckoutCoupon.queryParamName, null, { replace: true });
    }

    const nextProjectCheckoutCoupon = coupons[projectCheckoutCouponIndex + 1];
    if (nextProjectCheckoutCoupon == null) {
      logger.warn('PROJECT_CHECKOUT_COUPON_INVALID', { currentCouponValue, coupons });
      toast('The coupon is no longer valid', 'root-toast', 'error', {
        toastId: 'invalid-coupon-checkout',
      });
      setInputValue(manager.form, 'coupon', '');
      return;
    }

    setInputValue(manager.form, 'coupon', nextProjectCheckoutCoupon.coupon);
  }, [coupons, manager.form, setSearchParam, total]);

  // Destroy the manager on unmount.
  // If we are running using hot reload, we should skip the first
  // cleanup because of the strict mode.
  const skipCleanup = useRef(import.meta.hot != null);
  useEffect(() => {
    return () => {
      if (skipCleanup.current) {
        skipCleanup.current = false;
        return;
      }

      manager.destroy();
    };
  }, [manager]);

  return manager;
}

export async function isStepAvailable(manager: ProjectCheckoutManager, name: string): Promise<boolean> {
  // When the form is still validating, we should wait until it's validated to check
  // if the step is available or not.
  if (isFormValidating(manager.form)) {
    await new Promise<void>((resolve) => {
      const cleanup = watchIsFormValidating(manager.form, (validated) => {
        if (!validated) {
          return;
        }
        cleanup();
        resolve();
      });
    });
  }

  const { validSteps } = manager.store.getState();

  const stepIndex = manager.steps.findIndex((s) => s.name == name);
  if (stepIndex === -1) {
    return false;
  }
  const previousSteps = manager.steps.slice(0, stepIndex);
  return previousSteps.every((step) => validSteps[step.name] === true);
}

export function useIsStepAvailable(manager: ProjectCheckoutManager, name: string): boolean {
  const validSteps = useStore(
    manager.store,
    useCallback((state) => state.validSteps, []),
  );

  const stepIndex = manager.steps.findIndex((s) => s.name == name);
  if (stepIndex === -1) {
    return false;
  }
  const previousSteps = manager.steps.slice(0, stepIndex);
  return previousSteps.every((step) => validSteps[step.name] === true);
}

export function useIsNextStepAvailable(manager: ProjectCheckoutManager): boolean {
  const currentStep = useStore(
    manager.store,
    useCallback((state) => state.currentStep, []),
  );
  const validSteps = useStore(
    manager.store,
    useCallback((state) => state.validSteps, []),
  );
  return validSteps[currentStep.name] ?? false;
}

export function useValidSteps(manager: ProjectCheckoutManager): Record<string, boolean> {
  return useStore(
    manager.store,
    useCallback((state) => state.validSteps, []),
  );
}

export function useIsStepValid(manager: ProjectCheckoutManager, name: string): boolean {
  const validSteps = useValidSteps(manager);
  return validSteps[name] ?? false;
}

export function getCurrentStep(manager: ProjectCheckoutManager): ProjectCheckoutCurrentStep {
  return manager.store.getState().currentStep;
}

export function useCurrentStep(manager: ProjectCheckoutManager): ProjectCheckoutCurrentStep {
  return useStore(
    manager.store,
    useCallback((state) => state.currentStep, []),
  );
}

export async function getCheckoutData(manager: ProjectCheckoutManager): Promise<ProjectCheckoutFormData> {
  // This method can will only be called at the end, so we are sure that the data is present and valid.
  const type = getInputValueOrThrow(manager.form, 'type');
  let ownerPhone = getInputValue(manager.form, 'owner.phone');
  if (isPhoneNumberEmpty(ownerPhone)) {
    ownerPhone = null;
  }

  const owner: ProjectCheckoutOwner = {
    email: getInputValueOrThrow(manager.form, 'owner.email'),
    phone: ownerPhone,
    person: {
      firstName: getInputValueOrThrow(manager.form, 'owner.person.firstName'),
      lastName: getInputValueOrThrow(manager.form, 'owner.person.lastName'),
    },
    timezone: getInputValueOrThrow(manager.form, 'owner.timezone'),
  };

  const addons: ProjectCheckoutAddons = {
    books: getInputValueOrThrow(manager.form, 'addons.books'),
    ebook: getInputValueOrThrow(manager.form, 'addons.ebook'),
  };

  const legacyboxQuantity = getInputValue(manager.form, 'addons.legacybox.quantity');
  if (legacyboxQuantity != null && legacyboxQuantity > 0) {
    addons.legacybox = {
      quantity: legacyboxQuantity,
      shipDate: dayjs
        .tz(getInputValueOrThrow(manager.form, 'addons.legacybox.shipDate'), 'America/Los_Angeles')
        .valueOf(),
      recipientName: getInputValueOrThrow(manager.form, 'addons.legacybox.recipientName'),
      addressLine1: getInputValueOrThrow(manager.form, 'addons.legacybox.addressLine1'),
      addressLine2: getInputValue(manager.form, 'addons.legacybox.addressLine2'),
      city: getInputValueOrThrow(manager.form, 'addons.legacybox.city'),
      state: getInputValueOrThrow(manager.form, 'addons.legacybox.state'),
      zipCode: getInputValueOrThrow(manager.form, 'addons.legacybox.zipCode'),
      country: getInputValueOrThrow(manager.form, 'addons.legacybox.country'),
    };
  }

  const rawCoupon = getInputValue(manager.form, 'coupon') ?? null;
  const coupon = rawCoupon != null && rawCoupon.trim().length > 0 ? rawCoupon : null;

  switch (type) {
    case 'AUTOBIOGRAPHY': {
      return {
        type,
        coupon,
        referrerUserId: getInputValue(manager.form, 'referrerUserId'),
        owner,
        addons,
      };
    }
    case 'BIOGRAPHY': {
      let recipientPhone = getInputValue(manager.form, 'recipient.phone');
      if (isPhoneNumberEmpty(recipientPhone)) {
        recipientPhone = null;
      }
      const recipient: ProjectCheckoutUser = {
        email: getInputValueOrThrow(manager.form, 'recipient.email'),
        phone: recipientPhone,
        person: {
          firstName: getInputValueOrThrow(manager.form, 'recipient.person.firstName'),
          lastName: getInputValueOrThrow(manager.form, 'recipient.person.lastName'),
        },
      };

      // We should always use dayjs to parse the date to avoid timezone issues.
      const sendOnDate = dayjs(getInputValueOrThrow(manager.form, 'gift.sendOn'));

      const gift: ProjectCheckoutGift = {
        from: getInputValueOrThrow(manager.form, 'gift.from'),
        message: getInputValueOrThrow(manager.form, 'gift.message'),
        sendOn: sendOnDate.valueOf(),
        sendPrintableCard: getInputValue(manager.form, 'gift.sendPrintableCard') ?? false,
      };

      return {
        type,
        coupon,
        referrerUserId: getInputValue(manager.form, 'referrerUserId'),
        owner,
        addons,
        gift,
        recipient,
      };
    }
    case 'BABYBOOK': {
      const subjectFirstName = getInputValue(manager.form, 'subject.firstName');
      const subject: ProjectCheckoutPerson = {
        firstName: subjectFirstName == null || subjectFirstName.length == 0 ? 'Baby' : subjectFirstName,
        lastName: owner.person.lastName,
      };

      return {
        type,
        coupon,
        referrerUserId: getInputValue(manager.form, 'referrerUserId'),
        owner,
        addons,
        name: getInputValueOrThrow(manager.form, 'name'),
        subject,
      };
    }
    case 'FREE': {
      return {
        type,
        coupon,
        owner,
        addons,
        name: getInputValueOrThrow(manager.form, 'name'),
        recipientPersonId: getInputValueOrThrow(manager.form, 'recipientPersonId'),
        subscriptionOwnerPersonId: getInputValueOrThrow(manager.form, 'subscriptionOwnerPersonId'),
      };
    }
  }
}

export async function goToStep(manager: ProjectCheckoutManager, name: string, replace = false): Promise<boolean> {
  const stepIndex = manager.steps.findIndex((s) => s.name == name);
  if (!(await isStepAvailable(manager, name))) {
    if (stepIndex > 0) {
      await goToStep(manager, manager.steps[stepIndex - 1].name, true);
      return false;
    }
    return false;
  }
  const { currentStep } = manager.store.getState();
  const step = manager.steps[stepIndex];

  // We should only run the `postStepCallback` function if we are
  // going to the next step. If the user is going back, it should not be triggered.
  if (stepIndex > currentStep.index) {
    const valid = await manager.steps[currentStep.index].postStepCallback?.(manager);
    if (valid === false) {
      return false;
    }
  }

  await step.preStepCallback?.(manager);

  if (currentStep.name !== name) {
    manager.store.setState({
      currentStep: {
        name: step.name,
        index: stepIndex,
      },
    });
  }

  // Make sure that we are at the right page, even if the state is the same
  if (getPathname() !== step.route) {
    manager.navigate(step.route + window.location.search, { replace });
  }

  return true;
}

export async function goToNextStep(manager: ProjectCheckoutManager): Promise<boolean> {
  const { currentStep, validSteps } = manager.store.getState();
  if (validSteps[currentStep.name] !== true) {
    return false;
  }

  if (currentStep.index + 1 >= manager.steps.length) {
    // There's no next step to go
    // We should run the postStepCallback of the last step.
    await manager.steps[currentStep.index].postStepCallback?.(manager);
    return false;
  }

  return goToStep(manager, manager.steps[currentStep.index + 1].name);
}
