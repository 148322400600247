import { useCallback, useEffect, useRef } from 'react';
import { createWidget } from '@typeform/embed';

import { StyledEmbeddedTypeform } from './EmbeddedTypeform.styles.js';

export interface BaseTypeformEvent {
  formId: string;
}

export interface TypeformSubmitEvent extends BaseTypeformEvent {
  responseId: string;
}

export interface EmbeddedTypeformProps {
  formId: string;
  fullScreen?: boolean;
  hiddenFields?: Record<string, string>;
  className?: string;
  onReady?: (e: BaseTypeformEvent) => void;
  onStarted?: (e: BaseTypeformEvent) => void;
  onQuestionChanged?: (e: BaseTypeformEvent) => void;
  onSubmit?: (e: TypeformSubmitEvent) => void;
  onClose?: () => void;
}

export function EmbeddedTypeform({ formId, fullScreen, hiddenFields, className, ...callbacks }: EmbeddedTypeformProps) {
  const widgetRef = useRef<ReturnType<typeof createWidget> | null>(null);
  const callbacksRef = useRef(callbacks);

  useEffect(() => {
    callbacksRef.current = callbacks;
  }, [callbacks]);

  const typeformWrapperRef = useCallback(
    (div: HTMLDivElement | null) => {
      if (div == null) {
        widgetRef.current?.unmount();
        widgetRef.current = null;
        return;
      }
      widgetRef.current = createWidget(formId, {
        fullScreen,
        container: div,
        onReady: (e) => callbacksRef.current.onReady?.(e),
        onStarted: (e) => callbacksRef.current.onStarted?.(e),
        onQuestionChanged: (e) => callbacksRef.current.onQuestionChanged?.(e),
        onSubmit: (e) => callbacksRef.current.onSubmit?.(e),
        onClose: () => callbacksRef.current.onClose?.(),
        hidden: hiddenFields,
      });
    },
    [formId, fullScreen, hiddenFields],
  );

  return <StyledEmbeddedTypeform ref={typeformWrapperRef} data-fullscreen={fullScreen} className={className} />;
}
