import Image1Src from '@/assets/legacybox-learn-more-1.webp';
import Image2Src from '@/assets/legacybox-learn-more-2.webp';
import Image3Src from '@/assets/legacybox-learn-more-3.webp';
import { RMButton } from '@/components/RMButton/RMButton.js';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer.js';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import {
  BodyContent,
  Buttons,
  Card,
  CardImage,
  CardsGrid,
  Header,
  LinkButtonWrapper,
} from './LegacyboxLearnMoreDialog.styles.js';

const CARDS = [
  {
    title: '1. Pack Your Memories',
    message: `We'll mail you a Legacybox kit to fill with your cherished photos. The kit includes a simple welcome guide and a pre-paid shipping label. Drop your filled Legacybox at any UPS location, and we'll handle the rest.`,
    imageSrc: Image1Src,
  },
  {
    title: '2. Digitized With Care',
    message: `The Legacybox team carefully digitizes each photo by hand, sending you personalized updates throughout the process. Your irreplaceable memories are treated with the utmost care at their secure, state-of-the-art facility.`,
    imageSrc: Image2Src,
  },
  {
    title: '3. Add to Remento',
    message: `Receive all your original photos back, plus digital copies that can be easily added to your Remento account. Use these photos to inspire new reflections, or add them to existing stories recorded on Remento.`,
    imageSrc: Image3Src,
  },
];

export interface LegacyboxLearnMoreDialogProps {
  onClose: () => void;
}

export function LegacyboxLearnMoreDialog({ onClose }: LegacyboxLearnMoreDialogProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMDialog.Root open variant="extra-large" onClose={onClose}>
      <RMDialog.Content>
        <Header rightAdornment={<RMCloseButton onClick={onClose} />} />
        <RMDialog.Body>
          <BodyContent>
            <RMText type="serif" size="xl" color="on-surface-primary" align="center" as="h1">
              Add old photos to your book in 3 simple steps
            </RMText>
            <RMSpacer direction="column" spacing="xs" />
            <RMText type="sans" size="s" color="on-surface-tertiary" align="center" as="h2">
              The most important thing you can do for your family, in three easy steps.
            </RMText>

            <RMSpacer direction="column" spacing="2xl" />

            <CardsGrid>
              {CARDS.map((c) => (
                <Card key={c.title}>
                  <CardImage src={c.imageSrc} alt="Legacybox information image" aria-hidden="true" />
                  <RMSpacer direction="column" spacing="md" />
                  <RMText type="serif" size={isMobile ? 's' : 'l'} color="on-surface-primary" as="dt">
                    {c.title}
                  </RMText>
                  <RMSpacer direction="column" spacing="sm" />
                  <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-primary" as="dd">
                    {c.message}
                  </RMText>
                </Card>
              ))}
            </CardsGrid>

            <Buttons>
              <RMButton background="primary" fullWidth onClick={onClose}>
                Close
              </RMButton>
              <LinkButtonWrapper
                href="https://help.remento.co/en/articles/10861178-how-it-works-legacybox-remento"
                target="_blank"
              >
                <RMButton background="transparent" fullWidth={isMobile}>
                  Learn more about Legacybox
                </RMButton>
              </LinkButtonWrapper>
            </Buttons>
          </BodyContent>
        </RMDialog.Body>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
