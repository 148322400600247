import { Fragment, ReactNode } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer.js';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';

import {
  Divider,
  EditButton,
  SectionHeader,
  StyledProjectCheckoutReview,
  WarningBox,
} from './ProjectCheckoutReview.styles.js';

export interface ProjectCheckoutReviewSectionItem {
  label: string;
  value: string | number | null | undefined;
  bold?: boolean;
}

export interface ProjectCheckoutReviewSection {
  title: string;
  items: ProjectCheckoutReviewSectionItem[];
  warning?: ReactNode;
  onEdit: () => void;
}

export interface ProjectCheckoutReviewProps {
  title: string;
  sections: ProjectCheckoutReviewSection[];
  CheckoutSummary: ReactNode;
}

export function ProjectCheckoutReview({ title, sections, CheckoutSummary }: ProjectCheckoutReviewProps) {
  const isMobile = useIsMobileViewport();

  return (
    <StyledProjectCheckoutReview>
      <RMText as="h1" type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        {title}
      </RMText>
      <RMSpacer spacing="xl" direction="column" />

      {isMobile && (
        <>
          {CheckoutSummary}
          <RMSpacer spacing="xl" direction="column" />
        </>
      )}

      {sections.map((section, sectionIndex) => (
        <Fragment key={section.title}>
          {sectionIndex > 0 && <Divider />}

          <SectionHeader>
            <RMText as="h2" type="serif" size="m" color="on-surface-primary">
              {section.title}
            </RMText>
            <EditButton onClick={section.onEdit} aria-label="Edit purchase information">
              <RMText as="label" type="sans" size="xs" color="on-surface-secondary" bold underline>
                Edit
              </RMText>
              <FontAwesomeIcon icon={faEdit} fontSize={16} />
            </EditButton>
          </SectionHeader>
          <RMSpacer spacing="md" direction="column" />
          <dl>
            {section.items.map((item, itemIndex) => (
              <Fragment key={item.label}>
                {itemIndex > 0 && <RMSpacer spacing="2xs" direction="column" />}

                <RMText as="dt" type="sans" size="s" color="on-surface-primary">
                  {item.label}:{' '}
                </RMText>
                <RMText
                  as="dd"
                  type="sans"
                  size="s"
                  color="on-surface-primary"
                  bold={item.bold ?? true}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {item.value}
                </RMText>
              </Fragment>
            ))}
          </dl>
          {section.warning != null && (
            <>
              <RMSpacer spacing="md" direction="column" />
              <WarningBox>
                <FontAwesomeIcon icon={faTriangleExclamation} color="var(--caution)" />
                <RMText type="sans" size="xs" color="caution">
                  {section.warning}
                </RMText>
              </WarningBox>
            </>
          )}
        </Fragment>
      ))}
    </StyledProjectCheckoutReview>
  );
}
