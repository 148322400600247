import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CartPreviewSrc from '@/assets/checkout-audience-myself.webp';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip.js';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';

import {
  CartDivider,
  CartItem,
  CartItemDescriptionList,
  CartItemDescriptionListItem,
  CartItemNameWrapper,
  CartItems,
  Header,
  HeaderTextWrapper,
  HeaderTitleWrapper,
  PreviewImage,
  SummaryCard,
} from './ExperimentalProjectCheckoutSummary.styles.mobile.js';
import { ExperimentalProjectCheckoutSummaryProps } from './ExperimentalProjectCheckoutSummary.types.js';

export function ExperimentalProjectCheckoutSummaryMobile({ total, items }: ExperimentalProjectCheckoutSummaryProps) {
  const isMobile = useIsMobileViewport();

  return (
    <SummaryCard role="complementary" aria-labelledby="checkout-summary-title">
      <RMText type="sans" size="xs" color="on-surface-primary" bold trackedOutCaps id="checkout-summary-title">
        ORDER SUMMARY
      </RMText>
      <Header>
        <PreviewImage src={CartPreviewSrc} alt="Cart preview" aria-hidden />
        <HeaderTextWrapper>
          <HeaderTitleWrapper id="checkout-summary-book-title">
            <RMText type="sans" size="xs" color="on-surface-primary">
              The Remento Book
            </RMText>
            <RMText type="sans" size="xs" color="on-surface-primary">
              $99
            </RMText>
          </HeaderTitleWrapper>
          <ul aria-labelledby="checkout-summary-book-title">
            <li>
              <RMText type="sans" size="xxs" color="on-secondary">
                1 year of unlimited storytelling
              </RMText>
            </li>
            <li>
              <RMText type="sans" size="xxs" color="on-secondary">
                Billed annually, cancel anytime
              </RMText>
            </li>
          </ul>
        </HeaderTextWrapper>
      </Header>
      <CartItems role="list" aria-label="Cart items">
        <CartDivider />
        {items
          .filter((item) => item.type !== 'project')
          .map((item) => (
            <CartItem key={item.name} role="listitem">
              <CartItemNameWrapper>
                <RMText type="sans" size="xs" color="on-surface-primary">
                  {item.name}
                </RMText>
                <RMText
                  type="sans"
                  size={isMobile ? 'xxs' : 's'}
                  color={item.price >= 0 ? 'on-surface-primary' : 'affirmative'}
                >
                  {item.price > 0 && '$' + item.price.toFixed(2)}
                  {item.price < 0 && '-$' + Math.abs(item.price).toFixed(2)}
                  {item.price === 0 && 'FREE'}
                </RMText>
              </CartItemNameWrapper>
              {item.type === 'legacybox' && (
                <CartItemDescriptionList>
                  <CartItemDescriptionListItem>
                    <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-secondary">
                      Includes $5.99 round-trip shipping
                    </RMText>
                  </CartItemDescriptionListItem>
                </CartItemDescriptionList>
              )}
            </CartItem>
          ))}
        <CartDivider />
        <CartItemNameWrapper role="listitem">
          <RMText type="sans" size="s" color="on-surface-primary" bold>
            Total due today
          </RMText>
          <RMText
            type="sans"
            size="s"
            color={total != null ? 'on-surface-primary' : 'on-surface-tertiary'}
            bold={total != null}
          >
            {total != null ? '$' + total.toFixed(2) : 'Loading...'}
          </RMText>
        </CartItemNameWrapper>

        <CartItemDescriptionListItem role="listitem">
          <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} color="on-surface-secondary">
            All books include free US Shipping
          </RMText>
          <RMTooltip
            showOnTouch
            side="top"
            message={
              <RMText type="sans" size="xxs" color="inverse-on-surface-primary">
                International shipping to the UK, Canada, Ireland, Australia, and New Zealand is available for an
                additional fee. Shipping fees will be charged separately when your book is ready to print. Rates vary by
                destination.&nbsp;
                <a href="https://intercom.help/remento/en/articles/8365901-where-can-remento-ship-to" target="_blank">
                  <RMText type="sans" size="xxs" color="inverse-on-surface-primary" underline>
                    Learn more
                  </RMText>
                </a>
                .
              </RMText>
            }
          >
            <FontAwesomeIcon icon={faCircleExclamation} size="sm" color="var(--on-secondary)" />
          </RMTooltip>
        </CartItemDescriptionListItem>
      </CartItems>
    </SummaryCard>
  );
}
