import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const FormGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--spacing-xl);
  column-gap: var(--spacing-lg);
  width: 100%;
`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--spacing-xl);
  column-gap: var(--spacing-lg);
  width: 100%;
`;

export const Field = styled.div<{ size: 'small' | 'large' }>`
  grid-column: ${(props) => (props.size === 'small' ? 'span 1' : 'span 2')};

  @media only screen and (max-width: 768px) {
    grid-column: span 2;
  }
`;

export const SubHeader = styled(RMText)`
  grid-column: span 2;
  border-top: 1px solid var(--border-hairline);
  padding-top: var(--spacing-lg);
  display: block;
`;
