import { useMemo } from 'react';

import { RMStepper, RMStepperItem } from '@/components/RMStepper/RMStepper';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import {
  goToStep,
  ProjectCheckoutManager,
  ProjectType,
  useCurrentStep,
  useValidSteps,
} from '../project-checkout.manager';

export interface ProjectCheckoutStepperContainerProps {
  projectType: ProjectType;
  manager: ProjectCheckoutManager;
}

export function ProjectCheckoutStepperContainer({ projectType, manager }: ProjectCheckoutStepperContainerProps) {
  const isMobile = useIsMobileViewport();
  const currentStep = useCurrentStep(manager);
  const validSteps = useValidSteps(manager);

  const stepperItems = useMemo<RMStepperItem[]>(() => {
    return manager.steps.map((s, i) => {
      // The step should be enabled if it's the current step, or if all of the previous steps are valid.
      const enabled = currentStep.index === i || manager.steps.slice(0, i).every((s2) => validSteps[s2.name]);
      return { label: s.label, disabled: !enabled };
    });
  }, [currentStep.index, manager.steps, validSteps]);

  return (
    <RMStepper
      layout={isMobile ? 'progress' : 'stepper'}
      activeStepIndex={currentStep.index}
      hideIndex={projectType === 'FREE'}
      steps={stepperItems}
      onStepChange={(newStepIndex) => {
        goToStep(manager, manager.steps[newStepIndex].name);
      }}
    />
  );
}
