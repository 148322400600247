import { useMemo } from 'react';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShippingAddressCountry } from '@remento/types/shipping';
import dayjs from 'dayjs';

import LegacyboxSrc from '@/assets/legacybox.webp';
import { RMDateInput } from '@/components/RMDateInput/RMDateInput.js';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMSelect } from '@/components/RMSelect';
import { RMSelectOption, SELECT_EMPTY_VALUE } from '@/components/RMSelect/RMSelect.types.js';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';
import { InputContainer, InputController } from '@/modules/form/container';
import { useInputValue } from '@/modules/form/input.js';

import { ProjectCheckoutForm } from '../../project-checkout.form.js';
import { ProjectCheckoutEbookCheckbox } from '../ProjectCheckoutEbookCheckbox/ProjectCheckoutEbookCheckbox.js';

import {
  Divider,
  Field,
  FormGrid,
  LearnMoreButton,
  LegacyboxFormWrapper,
  LegacyboxImage,
  LegacyboxLeftPanel,
  LegacyboxPanelsWrapper,
  LegacyboxRightPanel,
  LegacyboxWrapper,
  StyledBiographyProjectCheckoutAddonsForm,
} from './BiographyProjectCheckoutAddonsForm.styles.js';

const BOOK_QUANTITY_OPTIONS = Array.from({ length: 100 }).map((_, i) => ({
  label: `${i + 1} ${
    i === 0 ? '(included in purchase)' : `(${i} extra book${i > 1 ? 's' : ''}) - $${59 * i} (save $${10 * i})`
  }`,
  value: `${i + 1}`,
}));

const LEGACYBOX_QUANTITY_OPTIONS = [
  {
    label: 'Select quantity',
    value: '0',
  },
  {
    label: '50 photos - $34.99 ($0.70 per photo) + $5.99 round-trip shipping',
    value: '50',
  },
  {
    label: '100 photos - $49.99 ($0.50 per photo) + $5.99 round-trip shipping',
    value: '100',
  },
  {
    label: '250 photos - $99.99 ($0.40 per photo) + $5.99 round-trip shipping',
    value: '250',
  },
  {
    label: '500 photos - $149.99 ($0.30 per photo) + $5.99 round-trip shipping',
    value: '500',
  },
  {
    label: '1,000 photos - $249.99 ($0.25 per photo) + $5.99 round-trip shipping',
    value: '1000',
  },
];

const STATE_OPTIONS: Record<string, RMSelectOption[]> = {
  US: [
    { value: SELECT_EMPTY_VALUE, label: 'Select a state', disabled: true },
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska  - Coming soon!', disabled: true },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii - Coming soon!', disabled: true },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ],
};

const COUNTRY_OPTIONS = [
  {
    label: 'United States',
    value: ShippingAddressCountry.US,
  },
];

export interface BiographyProjectCheckoutAddonsFormProps {
  form: ProjectCheckoutForm;
  experimentalCopy: boolean;
  onLegacyBoxLearnMore: () => void;
}

export function BiographyProjectCheckoutAddonsForm({
  form,
  experimentalCopy,
  onLegacyBoxLearnMore,
}: BiographyProjectCheckoutAddonsFormProps) {
  const isMobile = useIsMobileViewport();

  // Legacybox
  const legacyboxQuantity = useInputValue(form, 'addons.legacybox.quantity');
  const legacyboxShippingCountry = useInputValue(form, 'addons.legacybox.country');
  const legacyboxStateOptions = legacyboxShippingCountry != null ? (STATE_OPTIONS[legacyboxShippingCountry] ?? []) : [];
  const legacyboxMinShipDate = useMemo(() => {
    // The minimum date that can be picked is the next PST day
    const now = dayjs();
    const nowPST = now.tz('America/Los_Angeles');
    const todayStartPST = nowPST.startOf('day');
    const tomorrowStartPST = todayStartPST.add(1, 'day');
    return tomorrowStartPST.format('YYYY-MM-DD');
  }, []);

  return (
    <StyledBiographyProjectCheckoutAddonsForm>
      <RMText as="h1" type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        {experimentalCopy ? 'Customize with add-ons' : 'Add ons'}
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <FormGrid>
        <InputController form={form} path="addons.books">
          {({ value, onChange, onBlur }) => (
            <Field size="large">
              <RMSelect
                id="book-quantity-select"
                boldLabel={!experimentalCopy}
                label={
                  experimentalCopy ? (
                    <>
                      <b>Number of hardcover books</b> (Save $10{isMobile ? '' : ' by adding now'})
                    </>
                  ) : (
                    'Number of books'
                  )
                }
                options={BOOK_QUANTITY_OPTIONS}
                value={value != null ? String(value) : '1'}
                onChange={(newValue) => {
                  onChange(parseInt(newValue, 10));
                  onBlur();
                }}
              />
            </Field>
          )}
        </InputController>

        <InputController form={form} path="addons.ebook">
          {({ value, onChange }) => (
            <Field size="large">
              <ProjectCheckoutEbookCheckbox
                value={!!value}
                experimentalStyling={experimentalCopy}
                onChange={onChange}
              />
            </Field>
          )}
        </InputController>

        <Field size="large">
          <LegacyboxWrapper role="region">
            <LearnMoreButton type="button" onClick={onLegacyBoxLearnMore}>
              <RMText type="sans" color="on-surface-tertiary" size="xxs" bold>
                Learn more
              </RMText>
              <FontAwesomeIcon icon={faCircleInfo} color="var(--on-surface-tertiary)" size="1x" />
            </LearnMoreButton>

            <LegacyboxPanelsWrapper>
              <LegacyboxRightPanel>
                <LegacyboxImage src={LegacyboxSrc} alt="Legacy Box" aria-hidden="true" />
                {!isMobile && (
                  <RMText type="serif" color="on-surface-primary" size="s" align="center">
                    Over 1 million families
                    <br />
                    trust Legacybox
                  </RMText>
                )}
              </LegacyboxRightPanel>

              <LegacyboxLeftPanel>
                {!isMobile && (
                  <RMText type="sans" color="on-surface-primary" size="xxs" bold>
                    NEW PARTNERSHIP
                  </RMText>
                )}
                <RMText type="sans" color="on-surface-primary" size="s2" bold as="h2">
                  Digitize your old photos
                </RMText>
                <RMText type="sans" color="on-surface-primary" size="xs">
                  We'll mail you a Legacybox kit, which you’ll use to send in your printed photos. Once digitized, use
                  these photos as storytelling prompts in Remento to capture the stories behind each memorable moment.
                </RMText>
              </LegacyboxLeftPanel>
            </LegacyboxPanelsWrapper>

            <InputController form={form} path="addons.legacybox.quantity">
              {({ value, onChange, onBlur }) => (
                <RMSelect
                  id="legacybox-quantity-select"
                  ariaLabel="Legacy box photos quantity"
                  options={LEGACYBOX_QUANTITY_OPTIONS}
                  value={value != null ? String(value) : '0'}
                  onChange={(newValue) => {
                    onChange(parseInt(newValue, 10));
                    onBlur();
                  }}
                />
              )}
            </InputController>

            {/* We cannot use aria-hidden for styling because radix will add the aria-hidden when opening the select,
                and in that case we don't actually want to hide the form from the user. */}
            <LegacyboxFormWrapper
              aria-hidden={legacyboxQuantity == null || legacyboxQuantity == 0}
              data-hidden={legacyboxQuantity == null || legacyboxQuantity == 0}
            >
              <Divider />
              <RMText as="h3" type="serif" size={isMobile ? 'm' : 'l'} color="on-surface-primary">
                When should we ship your Legacybox?
              </RMText>

              <InputController form={form} path="addons.legacybox.shipDate" immediate>
                {(props) => (
                  <Field size="large">
                    <RMDateInput
                      id="legacybox-ship-date"
                      type="date"
                      label="Legacybox ship date"
                      placeholder="XX/XX/XXXX"
                      min={legacyboxMinShipDate}
                      {...props}
                    />
                    <RMSpacer spacing="xs" direction="column" />
                    <RMText size="xs" type="sans" color="on-surface-tertiary">
                      The date we’ll ship your box, which will arrive within 7 business days of this date.
                    </RMText>
                  </Field>
                )}
              </InputController>

              <RMText as="h3" type="serif" size={isMobile ? 'm' : 'l'} color="on-surface-primary">
                Where should we ship your Legacybox?
              </RMText>

              <InputContainer form={form} path="addons.legacybox.recipientName">
                {(props) => (
                  <RMInput
                    id="legacybox-shipping-address-recipient-name"
                    label="Recipient name*"
                    placeholder="First Last"
                    {...props}
                  />
                )}
              </InputContainer>
              <InputContainer form={form} path="addons.legacybox.addressLine1">
                {(props) => (
                  <RMInput
                    id="legacybox-shipping-address-line1"
                    label="Address Line 1*"
                    placeholder="65 Hansen Way"
                    {...props}
                  />
                )}
              </InputContainer>
              <InputContainer form={form} path="addons.legacybox.addressLine2">
                {(props) => (
                  <RMInput
                    id="legacybox-shipping-address-line2"
                    label="Address Line 2"
                    placeholder="Apartment 4"
                    {...props}
                  />
                )}
              </InputContainer>
              <InputContainer form={form} path="addons.legacybox.city">
                {(props) => (
                  <RMInput
                    id="legacybox-shipping-address-city"
                    label="City/Town*"
                    placeholder="Los Angeles"
                    {...props}
                  />
                )}
              </InputContainer>
              <InputController form={form} path="addons.legacybox.state">
                {({ value, error, onBlur, onChange }) => (
                  <RMSelect
                    id="legacybox-shipping-address-state"
                    label="State*"
                    options={legacyboxStateOptions}
                    value={value ?? SELECT_EMPTY_VALUE}
                    error={error}
                    onChange={(value) => {
                      onChange(value);
                      onBlur();
                    }}
                  />
                )}
              </InputController>
              <InputContainer form={form} path="addons.legacybox.zipCode">
                {(props) => (
                  <RMInput id="legacybox-shipping-address-zip" label="Zip/Post code*" placeholder="93034" {...props} />
                )}
              </InputContainer>
              <InputController form={form} path="addons.legacybox.country">
                {({ value, error, onBlur, onChange }) => (
                  <RMSelect
                    id="legacybox-shipping-address-country"
                    label="Country*"
                    options={COUNTRY_OPTIONS}
                    value={value ?? ''}
                    error={error}
                    onChange={(value) => {
                      onChange(value as ShippingAddressCountry);
                      onBlur();
                    }}
                  />
                )}
              </InputController>

              <RMText size="xs" type="sans" color="on-surface-tertiary">
                Note: After receiving the box, you can select a different return address for your photos.
              </RMText>
            </LegacyboxFormWrapper>
          </LegacyboxWrapper>
        </Field>
      </FormGrid>
    </StyledBiographyProjectCheckoutAddonsForm>
  );
}
