import { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ConflictError, ConflictErrorType } from '@remento/types/error';
import { Person } from '@remento/types/person';
import { ShippingAddressCountry } from '@remento/types/shipping';
import { User } from '@remento/types/user';
import Cookies from 'js-cookie';

import { PageLoader } from '@/components/PageLoader/PageLoader.js';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { removeToast, toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getQueryParam } from '@/hooks/useQueryParam';
import { getInputValue, setInputValues } from '@/modules/form/input';
import { getCheckoutGiftPath, getNewProjectPath, getSigninPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { captureException } from '@/utils/captureException';
import { getCurrentTimezoneId } from '@/utils/tz';

import { ExperimentalProjectCheckoutLayout } from '../components/ExperimentalProjectCheckoutLayout/ExperimentalProjectCheckoutLayout.js';
import { ExperimentalProjectCheckoutMobileLayout } from '../components/ExperimentalProjectCheckoutLayout/ExperimentalProjectCheckoutMobileLayout.js';
import { ProjectCheckoutDesktopLayout } from '../components/ProjectCheckoutLayout/ProjectCheckoutDesktopLayout.js';
import { ProjectCheckoutMobileLayout } from '../components/ProjectCheckoutLayout/ProjectCheckoutMobileLayout.js';
import { createProjectCheckoutForm } from '../project-checkout.form.js';
import { ProjectCheckoutManagerProvider } from '../project-checkout.manager.context.js';
import {
  goToNextStep,
  ProjectCheckoutManager,
  ProjectType,
  useCheckoutTotal,
  useCreateProjectCheckoutManager,
  useCurrentStep,
  useIsStepValid,
} from '../project-checkout.manager.js';
import { PROJECT_CHECKOUT_STEPS } from '../project-checkout.steps.js';
import { getProjectCheckoutCoupons, useIsExperimentalProjectCheckoutLayoutEnabled } from '../project-checkout.utils.js';
import { ProjectCheckoutServicesProvider } from '../project-checkout-services.context..js';

import { ExperimentalProjectCheckoutSummaryContainer } from './ExperimentalProjectCheckoutSummary.container.js';
import { ProjectCheckoutStepperContainer } from './ProjectCheckoutStepper.container.js';
import { ProjectCheckoutSummaryContainer } from './ProjectCheckoutSummary.container.js';

interface ContinueButtonContainerProps {
  manager: ProjectCheckoutManager;
  onClick: () => void;
}

function ContinueButtonContainer({ manager, onClick }: ContinueButtonContainerProps) {
  const currentStep = useCurrentStep(manager);
  const isCurrentStepValid = useIsStepValid(manager, currentStep.name);
  const total = useCheckoutTotal(manager);

  const label = useMemo(() => {
    if (currentStep.name !== 'review') {
      return 'Continue';
    }
    if (total.status === 'fetched' && total.price === 0) {
      return 'Finish';
    }
    return 'Continue to payment';
  }, [currentStep.name, total]);

  return (
    <RMButton background="primary" disabled={!isCurrentStepValid} onClick={onClick} fullWidth autoLoading>
      {label}
    </RMButton>
  );
}

interface InternalProjectCheckoutContainerProps {
  // Preload the necessary data in a parent container to minimize form recreation during data loading.
  // This approach helps prevent validation issues that can occur when the form is created before all required data
  // (such as email and name) is fully loaded.
  // Example: if the user attempts to access the review page while the data is still loading,
  // the form will be invalid due to missing data, potentially redirecting the user incorrectly to a previous step.
  user: User | null;
  userPerson: Person | null;
  projectType: ProjectType;
}

function InternalProjectCheckoutContainer({ user, userPerson, projectType }: InternalProjectCheckoutContainerProps) {
  // Services
  const { redirectService, checkoutAnalyticsService } = useServices();
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  // User state
  const signOut = useSignOut();
  const signedInUser = useMemo(() => {
    if (user == null) {
      return null;
    }

    const name = userPerson?.name ?? null;
    const email = user.communicationChannels.email;

    return {
      name,
      email,
    };
  }, [user, userPerson]);

  // AB Testing
  const experimentalLayout = useIsExperimentalProjectCheckoutLayoutEnabled(projectType);

  // Manager state
  const coupons = useMemo(() => (user !== undefined ? getProjectCheckoutCoupons(user?.coupons) : []), [user]);
  const steps = useMemo(() => {
    return PROJECT_CHECKOUT_STEPS[projectType].filter((s) => {
      // In the new layout, the audience tab is outside of the checkout manager
      if (!experimentalLayout) {
        return true;
      }
      return s.name !== 'audience';
    });
  }, [experimentalLayout, projectType]);
  const manager = useCreateProjectCheckoutManager(
    useMemo(
      () =>
        createProjectCheckoutForm({
          type: projectType,
          owner: {
            person: {
              firstName: userPerson?.name?.first,
              lastName: userPerson?.name?.last,
            },
            email: user?.communicationChannels.email,
            phone: user?.communicationChannels.phone,
            timezone: getCurrentTimezoneId(),
          },
          gift: {
            message: experimentalLayout
              ? `I'm gifting you Remento so that we can have a book of your best stories. Simply talk to share your memories - no writing needed! Together, we'll create a life story book that captures your voice and memories for generations to come.`
              : `I'm gifting you Remento because your stories matter, and I want to preserve them for our family. Simply talk to share your memories - no writing needed! Each week, you'll receive questions and you’ll record your answers. Together, we'll create a life story book that captures your voice and memories for generations to come.`,
          },
          addons: {
            books: projectType === 'FREE' ? 0 : 1,
            ebook: false,
            legacybox: {
              quantity: 0,
              country: ShippingAddressCountry.US,
            },
          },
          referrerUserId: getQueryParam('referrer-user-id') ?? Cookies.get('referrer-user-id') ?? undefined,
          recipientPersonId: getQueryParam('recipient-person-id') ?? undefined,
          subscriptionOwnerPersonId: getQueryParam('subscription-owner-person-id') ?? undefined,
        }),
      [
        experimentalLayout,
        projectType,
        user?.communicationChannels.email,
        user?.communicationChannels.phone,
        userPerson?.name?.first,
        userPerson?.name?.last,
      ],
    ),
    coupons,
    steps,
  );
  const currentStep = useCurrentStep(manager);

  // Callbacks
  const goToSignIn = useCallback(async () => {
    const hostEmail = getInputValue(manager.form, 'owner.email');
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true, defaultEmail: hostEmail }));
    removeToast(ConflictErrorType.USER_ALREADY_EXISTS);
  }, [manager.form, navigate, redirectService]);

  const handleSignOut = useCallback(async () => {
    setInputValues(manager.form, {
      owner: {
        person: {
          firstName: '',
          lastName: '',
        },
        email: '',
        phone: {
          countryCode: '',
          number: '',
        },
      },
    });
    await signOut(false);
  }, [manager.form, signOut]);

  const handleGoToNextStep = useCallback(async () => {
    try {
      await goToNextStep(manager);
    } catch (error) {
      if (error instanceof ConflictError && error.data?.type === ConflictErrorType.USER_ALREADY_EXISTS) {
        toast(
          <span>
            It looks like this email is already associated with a Remento account. Please login{' '}
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={goToSignIn}>
              here
            </span>
            .
          </span>,
          'full-screen-toast',
          'error',
          { toastId: ConflictErrorType.USER_ALREADY_EXISTS, autoClose: 10000, closeOnClick: false },
        );
      } else if (error instanceof ConflictError && error.data?.type === ConflictErrorType.STORYTELLER_ALREADY_EXISTS) {
        toast(
          <RMText type="sans" size="xs" color="on-surface-primary">
            You have already purchased a book for this storyteller. You can create a free project for them{' '}
            <a onClick={() => navigate(getNewProjectPath())}>
              <RMText type="sans" size="xs" color="on-surface-primary" bold underline>
                here
              </RMText>
            </a>
            .
          </RMText>,
          'root-toast',
          'error',
          {
            autoClose: 10000,
          },
        );
        return;
      } else if (error instanceof ConflictError && error.data?.type === ConflictErrorType.STORYTELLER_SAME_USER) {
        toast(
          <RMText type="sans" size="xs" color="on-surface-primary">
            You and the gift recipient must have different email addresses.{' '}
            <a
              target="_blank"
              href="https://help.remento.co/en/articles/8634399-gifting-remento-to-multiple-people#h_e390000de4"
            >
              <RMText type="sans" size="xs" color="on-surface-primary" bold underline>
                Learn more.
              </RMText>
            </a>
          </RMText>,
          'root-toast',
          'error',
        );
        return;
      } else {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    }
  }, [goToSignIn, manager, navigate]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate, redirectService]);

  // Analytics
  useEffect(() => {
    checkoutAnalyticsService.onCheckoutArrived(
      projectType === 'AUTOBIOGRAPHY' ? 'autobiography' : projectType === 'BIOGRAPHY' ? 'biography' : 'babybook',
    );
  }, [checkoutAnalyticsService, projectType]);

  if (experimentalLayout) {
    if (isMobile) {
      return (
        <ProjectCheckoutManagerProvider value={manager}>
          <ExperimentalProjectCheckoutMobileLayout
            Stepper={<ProjectCheckoutStepperContainer projectType={projectType} manager={manager} />}
            Summary={
              currentStep.name !== 'review' ? (
                <ExperimentalProjectCheckoutSummaryContainer manager={manager} accordion />
              ) : null
            }
            ContinueButton={<ContinueButtonContainer manager={manager} onClick={handleGoToNextStep} />}
            currentStep={currentStep.name}
            showSubscriptionNote
            user={user ? signedInUser : null}
            onSignIn={handleSignIn}
            onSignOut={handleSignOut}
          >
            <Outlet />
          </ExperimentalProjectCheckoutMobileLayout>{' '}
        </ProjectCheckoutManagerProvider>
      );
    }
    return (
      <ProjectCheckoutManagerProvider value={manager}>
        <ExperimentalProjectCheckoutLayout
          Stepper={<ProjectCheckoutStepperContainer projectType={projectType} manager={manager} />}
          ContinueButton={<ContinueButtonContainer manager={manager} onClick={handleGoToNextStep} />}
          RightPanel={<ExperimentalProjectCheckoutSummaryContainer manager={manager} />}
          currentStep={currentStep.name}
          user={user ? signedInUser : null}
          onSignIn={handleSignIn}
          onSignOut={handleSignOut}
        >
          <Outlet />
        </ExperimentalProjectCheckoutLayout>
      </ProjectCheckoutManagerProvider>
    );
  }

  if (isMobile) {
    return (
      <ProjectCheckoutManagerProvider value={manager}>
        <ProjectCheckoutMobileLayout
          Stepper={<ProjectCheckoutStepperContainer projectType={projectType} manager={manager} />}
          ContinueButton={<ContinueButtonContainer manager={manager} onClick={handleGoToNextStep} />}
          Header={currentStep.name !== 'review' ? <ProjectCheckoutSummaryContainer manager={manager} /> : null}
          currentStep={currentStep}
        >
          <Outlet />
        </ProjectCheckoutMobileLayout>
      </ProjectCheckoutManagerProvider>
    );
  }

  return (
    <ProjectCheckoutManagerProvider value={manager}>
      <ProjectCheckoutDesktopLayout
        Stepper={<ProjectCheckoutStepperContainer projectType={projectType} manager={manager} />}
        ContinueButton={<ContinueButtonContainer manager={manager} onClick={handleGoToNextStep} />}
        RightPanel={<ProjectCheckoutSummaryContainer manager={manager} />}
        currentStep={currentStep}
        user={signedInUser}
        showPurchaseGiftCard={projectType !== 'FREE'}
        onSignIn={handleSignIn}
        onSignOut={handleSignOut}
        onPurchaseGiftCard={() => navigate(getCheckoutGiftPath())}
      >
        <Outlet />
      </ProjectCheckoutDesktopLayout>
    </ProjectCheckoutManagerProvider>
  );
}

interface ProjectCheckoutUserLoaderContainerProps {
  projectType: ProjectType;
}

function ProjectCheckoutUserLoaderContainer({ projectType }: ProjectCheckoutUserLoaderContainerProps) {
  const user = useUser();
  const userPersonQuery = usePersonQuery(user?.personId);

  if (user === undefined || (user !== null && userPersonQuery.data == null)) {
    return <PageLoader />;
  }

  return (
    <InternalProjectCheckoutContainer projectType={projectType} user={user} userPerson={userPersonQuery.data ?? null} />
  );
}

interface ProjectCheckoutContainerProps {
  projectType: ProjectType;
}

export function ProjectCheckoutContainer({ projectType }: ProjectCheckoutContainerProps) {
  return (
    <RementoPage type="empty">
      <ProjectCheckoutServicesProvider>
        <ProjectCheckoutUserLoaderContainer projectType={projectType} />
      </ProjectCheckoutServicesProvider>
    </RementoPage>
  );
}
