import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledProjectCheckoutMobileLayout = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  background-color: var(--surface-brightest);
`;

export const Header = styled.header`
  border-bottom: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-bottom-1);
`;

export const ScrollArea = styled.div`
  height: 100%;
  overflow: auto;
`;

export const Wordmark = styled(RMWordmark)`
  margin: var(--spacing-lg) var(--spacing-md);
  height: 1rem;
  align-self: self-start;
`;

export const Main = styled.main`
  padding: var(--spacing-md);

  body[data-mobile='true'] & {
    position: relative;
    min-height: 100%;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  background-color: var(--surface-brightest);

  &[data-sticky='true'] {
    border-top: 1px solid var(--border-hairline);
    box-shadow: var(--elevation-top-2);
  }

  &[data-sticky='false'] {
    margin-left: calc(var(--spacing-md) * -1);
    width: calc(100% + var(--spacing-md) * 2);
    padding: var(--spacing-xl) var(--spacing-md);
  }
`;

export const NoteCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  width: 100%;
  background-color: var(--surface-bright);
  border-radius: var(--radius-smooth);
`;

export const AuthButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--spacing-xl);
`;
