import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { UserPurchaseStatus } from '@remento/types/user';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import lottie, { AnimationItem } from 'lottie-web';

import DancingBookLottieAnimation from '@/@assets/dancing-book-lottie-animation.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMLoader } from '@/components/RMLoader/RMLoader';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import {
  ProjectCheckoutServicesProvider,
  useProjectCheckoutServices,
} from '@/modules/project-checkout/project-checkout-services.context.';
import { getCheckoutGetStartedPath, RementoPage } from '@/modules/routing';
import { USER_COOKIE_DOMAIN, useServices } from '@/Services.tsx';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { CheckoutSession, useCheckoutSession } from '@/services/api/checkout';
import { usePollUserPurchaseUntilCompleted, useUserPurchaseQuery } from '@/services/api/user/user.service.hook';
import { IS_DEV } from '@/utils/isDev';

import {
  Content,
  Divider,
  Header,
  PageLoaderSlowLoadWrapper,
  PageLoaderSupportAnchor,
  PageLoaderWrapper,
  StyledProjectCheckoutSuccessPage,
  Wordmark,
} from './ProjectCheckoutSuccessPage.styles';

function getShareASaleUrl(session: CheckoutSession): string {
  const url = new URL('https://www.shareasale.com/sale.cfm');
  url.searchParams.set('tracking', session.invoiceNumber ?? '');
  url.searchParams.set('amount', String(session.paid));
  url.searchParams.set('merchantID', '146714');
  url.searchParams.set('transtype', 'sale');

  if (session.couponId !== null) {
    url.searchParams.set('couponId', session.couponId);
  }

  return url.toString();
}

function InternalProjectCheckoutSuccessPage() {
  const navigate = useNavigate();
  const user = useUser();
  const [searchParams] = useSearchParams();
  const isMobile = useIsMobileViewport();
  const { checkoutAnalyticsService, userService, projectCacheService } = useServices();

  const { projectCheckoutFormRepository } = useProjectCheckoutServices();

  const checkoutSessionId = searchParams.get('checkout-session-id') ?? searchParams.get('checkoutSessionId');
  const checkoutSession = useCheckoutSession(checkoutSessionId);

  // Poll the user purchased until it's completed
  const userPurchaseId = checkoutSession.data?.userPurchaseId;
  const userPurchaseQuery = useUserPurchaseQuery(userPurchaseId);
  usePollUserPurchaseUntilCompleted(userPurchaseId, 1000);
  const isUserPurchaseLoading = useMemo(() => {
    if (user === undefined) {
      return true;
    }
    if (checkoutSession.data == null) {
      return true;
    }
    if (checkoutSession.data.userPurchaseId == null) {
      return false;
    }
    return userPurchaseQuery.data == null || userPurchaseQuery.data.status === UserPurchaseStatus.PENDING;
  }, [checkoutSession.data, user, userPurchaseQuery.data]);

  const projectId = searchParams.get('projectId');
  const checkoutType = (searchParams.get('checkout-type') ?? searchParams.get('projectType')) as
    | 'BIOGRAPHY'
    | 'AUTOBIOGRAPHY'
    | 'BABYBOOK'
    | 'FREE'
    | 'BUNDLE'
    | 'GIFT-CARD';

  // Lottie animation
  const animationRef = useRef<AnimationItem | null>(null);
  const lottieRef = useCallback((node: HTMLDivElement | null) => {
    if (node === null) {
      if (animationRef.current) {
        animationRef.current.destroy();
        animationRef.current = null;
      }
      return;
    }

    const animation = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: DancingBookLottieAnimation,
    });
    animationRef.current = animation;
  }, []);

  useLayoutEffect(() => {
    if (checkoutSession.data == null || isUserPurchaseLoading) return;

    const { price, paid } = checkoutSession.data;
    // If the user successfully paid, there will be an invoiceNumber
    if (checkoutSession.data.invoiceNumber != null) {
      checkoutAnalyticsService.onCheckoutSuccess(checkoutSession.data.invoiceNumber, price, paid);
    }

    // Cleaning checkout form data after payment
    projectCheckoutFormRepository.clear();

    // Remove the referral coupon cookie
    Cookies.remove('referral-coupon', {
      domain: USER_COOKIE_DOMAIN,
    });

    if (checkoutType === 'FREE' && paid == 0) {
      navigate(getCheckoutGetStartedPath(searchParams), { replace: true });
      return;
    }

    const animationTimeout = setTimeout(async () => {
      if (checkoutType === 'BUNDLE' || checkoutType === 'GIFT-CARD') {
        if (user === null) {
          navigate('/checkout/survey?' + searchParams.toString(), { replace: true });
          return;
        }
        navigate(getCheckoutGetStartedPath(searchParams), { replace: true });
        return;
      }

      if (user != null) {
        // Make sure to refresh the user one more time just to make sure that everything is up to date.
        // TODO: In the future maybe the user purchase could return the user to avoid doing this.
        await userService.refreshUser();
        await projectCacheService.invalidateProjectsCache();
        navigate(getCheckoutGetStartedPath(searchParams), { replace: true });
      } else if (user == null) {
        navigate('/checkout/survey?' + searchParams.toString(), { replace: true });
      } else {
        navigate(`/?${searchParams.toString()}`, { replace: true });
      }
    }, 4000);

    return () => clearTimeout(animationTimeout);
  }, [
    searchParams,
    user,
    projectCheckoutFormRepository,
    navigate,
    checkoutAnalyticsService,
    checkoutSession.data,
    projectId,
    checkoutType,
    isUserPurchaseLoading,
    userService,
    projectCacheService,
  ]);

  //  If the loading is taking more than 10 seconds, show additional copy to the user
  const loadStartTime = useRef(Date.now());
  const slowLoadTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isSlowLoadDetected, setIsSlowLoadDetected] = useState(false);
  useEffect(() => {
    if (!isUserPurchaseLoading) {
      // Trigger an analytics with the time it took to process and clear the timeout, if any.
      checkoutAnalyticsService.onCheckoutProcessingCompleted((Date.now() - loadStartTime.current) / 1000);
      return;
    }

    slowLoadTimeoutId.current = setTimeout(
      () => {
        setIsSlowLoadDetected(true);
        slowLoadTimeoutId.current = null;
      },
      IS_DEV ? 1000 : 10000,
    );

    return () => {
      if (slowLoadTimeoutId.current != null) {
        clearTimeout(slowLoadTimeoutId.current);
        slowLoadTimeoutId.current = null;
      }
    };
  }, [checkoutAnalyticsService, isUserPurchaseLoading]);

  // Redirect to the start of the flow if no checkout session id
  // is present or if the id is invalid
  if (checkoutSessionId === null) {
    return <Navigate to="/checkout" />;
  }
  if (checkoutSession.error) {
    return <Navigate to="/checkout" />;
  }

  // If the user is signed in, show the loader until the order is processed
  if (!checkoutSession.data || isUserPurchaseLoading) {
    return (
      <StyledProjectCheckoutSuccessPage>
        <PageLoaderWrapper>
          <motion.div key="loader" layout transition={{ duration: 0.5, ease: 'easeInOut' }}>
            <RMLoader />
          </motion.div>

          {isSlowLoadDetected && (
            <PageLoaderSlowLoadWrapper
              key="slow-load-copy"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
              <RMText type="serif" size="l" color="on-surface-primary" align="center">
                Finalizing your order...
              </RMText>
              <RMText type="sans" size="s" color="on-surface-primary" align="center">
                Please wait on this page while your order finishes processing. This could take several minutes.
              </RMText>
              <PageLoaderSupportAnchor href="mailto:support@remento.co">
                <RMButton background="transparent">Questions? Contact support@remento.co.</RMButton>
              </PageLoaderSupportAnchor>
            </PageLoaderSlowLoadWrapper>
          )}
        </PageLoaderWrapper>
      </StyledProjectCheckoutSuccessPage>
    );
  }

  return (
    <StyledProjectCheckoutSuccessPage>
      <Header isMobile={isMobile}>
        <Wordmark />
      </Header>
      <Divider />

      <Content>
        <div ref={lottieRef} />
        <RMText type="serif" size="xl" color="on-surface-primary">
          Payment successful!
        </RMText>
      </Content>

      <img src={getShareASaleUrl(checkoutSession.data)} width="1" height="1" />
    </StyledProjectCheckoutSuccessPage>
  );
}

export function ProjectCheckoutSuccessPage() {
  return (
    <RementoPage type="empty">
      <ProjectCheckoutServicesProvider>
        <InternalProjectCheckoutSuccessPage />
      </ProjectCheckoutServicesProvider>
    </RementoPage>
  );
}
