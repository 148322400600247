import { styled } from '@linaria/react';

export const StyledBiographyProjectCheckoutAddonsForm = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const FormGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--spacing-xl);
  column-gap: var(--spacing-lg);
  width: 100%;
`;

export const Field = styled.div<{ size: 'small' | 'large' }>`
  grid-column: ${(props) => (props.size === 'small' ? 'span 1' : 'span 2')};

  @media only screen and (max-width: 768px) {
    grid-column: span 2;
  }
`;

export const LegacyboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-xl);
  border: 1px solid var(--on-surface-tertiary);
  border-radius: var(--radius-regular);
`;

export const LearnMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-2xs);
  padding: var(--spacing-2xs);
  align-self: flex-end;

  body[data-mobile='true'] & {
    position: absolute;
  }
`;

export const LegacyboxPanelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-3xl);
  padding-bottom: var(--spacing-xs);

  body[data-mobile='true'] & {
    flex-direction: column;
    gap: 0;
  }
`;

export const LegacyboxRightPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 200px;
  gap: var(--spacing-xs);

  body[data-mobile='true'] & {
    flex: 0 0 150px;
  }
`;

export const LegacyboxImage = styled.img`
  width: 200px;
`;

export const LegacyboxLeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding-top: var(--spacing-md);
  padding-right: var(--spacing-3xl);
`;

export const LegacyboxFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);

  &[data-hidden='true'] {
    display: none;
  }

  & > h3 {
    padding-bottom: var(--spacing-2xs);
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--border-hairline);
  margin: var(--spacing-lg) 0;
`;
