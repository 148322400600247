import { ReactNode } from 'react';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useInputValue } from '@/modules/form/input';

import { ProjectCheckoutForm } from '../../project-checkout.form.js';

import { Container, Divider, EditButton, Link, Row } from './FreeProjectCheckoutReview.styles.js';

export interface FreeProjectCheckoutReviewProps {
  form: ProjectCheckoutForm;
  storytellerName: string;
  onEditProjectInfo: () => void;
  CheckoutSummary: ReactNode;
}

export function FreeProjectCheckoutReviewProps({
  form,
  storytellerName,
  onEditProjectInfo,
  CheckoutSummary,
}: FreeProjectCheckoutReviewProps) {
  const isMobile = useIsMobileViewport();

  const projectName = useInputValue(form, 'name');
  const books = useInputValue(form, 'addons.books') ?? 0;
  const ebook = useInputValue(form, 'addons.ebook') ?? false;

  return (
    <Container>
      <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Review your new project
      </RMText>
      <RMSpacer spacing="xl" direction="column" />

      {isMobile && (
        <>
          {CheckoutSummary}
          <RMSpacer spacing="xl" direction="column" />
        </>
      )}

      <Row>
        <RMText type="serif" size={isMobile ? 'm' : 'l'} color="on-surface-primary">
          New project information
        </RMText>
        <EditButton onClick={onEditProjectInfo}>
          <RMText type="sans" size="xs" color="primary" bold underline>
            Edit
          </RMText>
          <FontAwesomeIcon icon={faEdit} fontSize={16} />
        </EditButton>
      </Row>
      <RMSpacer spacing="md" direction="column" />
      <RMText type="sans" size="s" color="on-surface-secondary">
        Project name: {projectName}
      </RMText>
      <RMSpacer spacing="2xs" direction="column" />
      <RMText type="sans" size="s" color="on-surface-secondary">
        Storyteller name: {storytellerName}
      </RMText>

      {(ebook || books > 0) && (
        <>
          <Divider />

          <Row>
            <RMText type="serif" size={isMobile ? 'm' : 'l'} color="on-surface-primary">
              Purchase details
            </RMText>
            <EditButton onClick={onEditProjectInfo}>
              <RMText type="sans" size="xs" color="primary" bold underline>
                Edit
              </RMText>
              <FontAwesomeIcon icon={faEdit} fontSize={16} />
            </EditButton>
          </Row>

          <RMSpacer spacing="md" direction="column" />
          {books > 0 && (
            <RMText type="sans" size="s" color="on-surface-secondary">
              Number of books: {books}
            </RMText>
          )}
          <RMSpacer spacing="2xs" direction="column" />
          <RMText type="sans" size="s" color="on-surface-secondary">
            Add E-book: {ebook ? 'Yes' : 'No'}
          </RMText>
        </>
      )}

      <Divider />

      <RMText type="sans" size="xs" color="on-surface-primary">
        This project is included in your current subscription and all platform features are available on this project
        through the duration of the subscription. Hardcover books can be purchased for this project at any time.
        <br />
        <br />
        Anything you create in this or any Remento project is private and secure per Remento’s{' '}
        <Link href="https://www.remento.co/policies/terms-of-service" target="_blank">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href="https://www.remento.co/policies/privacy-policy" target="_blank">
          Privacy Policy
        </Link>
        .
      </RMText>
    </Container>
  );
}
