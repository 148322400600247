import { RMInput } from '@/components/RMInput/RMInput';
import { RMPhoneInput } from '@/components/RMPhoneInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';

import { ProjectCheckoutForm } from '../../project-checkout.form.js';

import { Container, Divider, Field, FormGrid, Link } from './BiographyProjectCheckoutContactForm.styles.js';

export interface BiographyProjectCheckoutContactFormProps {
  form: ProjectCheckoutForm;
  firstNameDisabled: boolean;
  lastNameDisabled: boolean;
  emailDisabled: boolean;
  phoneDisabled: boolean;
}

export function BiographyProjectCheckoutContactForm({
  form,
  firstNameDisabled,
  lastNameDisabled,
  emailDisabled,
  phoneDisabled,
}: BiographyProjectCheckoutContactFormProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container>
      <RMText as="h1" type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Purchase details
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <FormGrid>
        <InputContainer form={form} path="owner.person.firstName">
          {(props) => (
            <Field size="small">
              <RMInput
                id="host-first-name"
                label={isMobile ? 'Your first name' : 'Your name'}
                aria-label="Your first name"
                placeholder="Your first name"
                disabled={firstNameDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputContainer form={form} path="owner.person.lastName">
          {(props) => (
            <Field size="small">
              <RMInput
                id="host-last-name"
                // \u00A0 === &nbsp;
                // We need this to keep both inputs at the same position.
                label={isMobile ? 'Your last name' : '\u00A0'}
                aria-label="Your last name"
                placeholder="Your last name"
                disabled={lastNameDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputContainer form={form} path="owner.email">
          {(props) => (
            <Field size="large">
              <RMInput
                id="host-email"
                label="Your email"
                placeholder="first.last@example.com"
                autoCapitalize="none"
                type="email"
                disabled={emailDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputController form={form} path="owner.phone">
          {(props) => (
            <Field size="large">
              <RMPhoneInput label="Your phone number (optional)" disabled={phoneDisabled} {...props} />
              <RMSpacer spacing="xs" direction="column" />
              <RMText size="xs" type="sans" color="on-surface-tertiary">
                Remento sends helpful reminders via text (currently US numbers only). Text STOP or disable in settings
                to unsubscribe. Msg & data rates may apply.
              </RMText>
            </Field>
          )}
        </InputController>
      </FormGrid>

      <Divider />
      <RMText type="sans" size="xs" color="on-surface-tertiary">
        By clicking Continue, you acknowledge that you have read and accept Remento's{' '}
        <Link href="https://www.remento.co/policies/terms-of-service" target="_blank">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href="https://www.remento.co/policies/privacy-policy" target="_blank">
          Privacy Policy
        </Link>{' '}
        , and consent to receiving product updates and occasional promotions via email and SMS. Unsubscribe at any time.
      </RMText>
    </Container>
  );
}
