import { PropsWithChildren, ReactNode, useLayoutEffect, useRef } from 'react';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';

import { ProjectCheckoutCurrentStep } from '../../project-checkout.manager.js';

import {
  Footer,
  Main,
  ScrollArea,
  StyledProjectCheckoutMobileLayout,
  Wordmark,
} from './ProjectCheckoutMobileLayout.styles.js';

type ProjectCheckoutMobileLayout = PropsWithChildren<{
  Stepper: ReactNode;
  Header: ReactNode;
  ContinueButton: ReactNode;
  currentStep: ProjectCheckoutCurrentStep;
}>;

export function ProjectCheckoutMobileLayout({
  Stepper,
  ContinueButton,
  Header,
  currentStep,
  children,
}: ProjectCheckoutMobileLayout) {
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollAreaRef.current == null) {
      return;
    }
    scrollAreaRef.current.scrollTop = 0;
  }, [currentStep]);

  return (
    <StyledProjectCheckoutMobileLayout>
      <header>
        <Wordmark destination="marketing" />
      </header>
      {Header}
      <ScrollArea ref={scrollAreaRef}>
        <Main>
          {Stepper}
          <RMSpacer spacing="md" direction="column" />
          {children}
        </Main>
      </ScrollArea>
      <Footer>{ContinueButton}</Footer>
    </StyledProjectCheckoutMobileLayout>
  );
}
