import { ShippingAddressCountry } from '@remento/types/shipping';
import dayjs from 'dayjs';
import EmailValidator from 'email-validator';
import { z } from 'zod';

import { createUserPhoneSchema } from '@/utils/phone-number';

import { Form } from '../form/@types.js';
import { DeepPartial } from '../form/@types-utils.js';
import { createForm } from '../form/form.js';

function createProjectCheckoutUserSchema() {
  return z.object({
    person: z.object({
      firstName: z.string().min(1, 'Enter their first name'),
      lastName: z.string().min(1, 'Enter their last name'),
    }),
    email: z.string().refine(EmailValidator.validate, { message: 'Enter a valid email address' }),
    phone: createUserPhoneSchema().nullish(),
  });
}

export function createProjectCheckoutFormSchema() {
  return z.object({
    /**
     * The type of the project.
     */
    type: z.union([z.literal('BIOGRAPHY'), z.literal('AUTOBIOGRAPHY'), z.literal('BABYBOOK'), z.literal('FREE')]),
    /**
     * A stripe coupon to be applied to the order.
     */
    coupon: z.string().nullish(),
    /**
     * The ID of the user who made the referral."
     */
    referrerUserId: z.string().nullish(),
    /**
     * The owner of the project.
     */
    owner: createProjectCheckoutUserSchema().extend({
      timezone: z.string(),
    }),
    /**
     * The additional products to be purchased with the project.
     */
    addons: z.object({
      ebook: z.boolean(),
      books: z.number(),
      /**
       * Legacybox integration
       * This is only used when the project is a `BIOGRAPHY` or `AUTOBIOGRAPHY`.
       */
      legacybox: z
        .object({
          quantity: z.number().nullish(),
          shipDate: z.string().nullish(),
          recipientName: z.string().nullish(),
          addressLine1: z.string().nullish(),
          addressLine2: z.string().nullish(),
          city: z.string().nullish(),
          state: z.string().nullish(),
          zipCode: z.string().nullish(),
          country: z.nativeEnum(ShippingAddressCountry).nullish(),
        })
        .nullish()
        .superRefine((data, ctx) => {
          // All validations should be done here.
          // We need to do this way to be able to conditionally apply the
          // validations only when the user is purchasing the legacybox addon.
          if (data?.quantity == null || data.quantity == 0) {
            return;
          }

          // Ship date validation
          if (data.shipDate == null || data.shipDate.length == 0) {
            ctx.addIssue({
              code: 'custom',
              message: 'Select a date',
              path: ['shipDate'],
            });
          } else {
            // We just have to make sure the date they selected is in the future from a PST perspective.
            const shipDatePST = dayjs.tz(data.shipDate, 'America/Los_Angeles');
            const now = dayjs();
            if (shipDatePST.isBefore(now)) {
              ctx.addIssue({
                code: 'custom',
                message: 'Select a future date',
                path: ['shipDate'],
              });
            }
          }
          // Recipient name validation
          if (data.recipientName == null || data.recipientName.length == 0) {
            ctx.addIssue({
              code: 'custom',
              message: 'Enter the recipient name',
              path: ['recipientName'],
            });
          }
          // Address line 1 validation
          if (data.addressLine1 == null || data.addressLine1.length == 0) {
            ctx.addIssue({
              code: 'custom',
              message: 'Enter address line 1',
              path: ['addressLine1'],
            });
          }
          // City validation
          if (data.city == null || data.city.length == 0) {
            ctx.addIssue({
              code: 'custom',
              message: 'Enter the city',
              path: ['city'],
            });
          }
          // State validation
          if (data.state == null || data.state.length == 0) {
            ctx.addIssue({
              code: 'custom',
              message: 'Enter the state',
              path: ['state'],
            });
          }
          // Zip code validation
          if (data.zipCode == null || data.zipCode.length == 0) {
            ctx.addIssue({
              code: 'custom',
              message: 'Enter the zip code',
              path: ['zipCode'],
            });
          }
          // Country validation
          if (data.country == null || data.country.length == 0) {
            ctx.addIssue({
              code: 'custom',
              message: 'Enter the country',
              path: ['country'],
            });
          }
        }),
    }),
    /**
     * The gift information.
     * This is only used when the project is a `BIOGRAPHY`.
     */
    gift: z.object({
      from: z.string().min(1, 'Enter the gift sender'),
      message: z
        .string()
        .min(1, 'Enter the gift message')
        .max(450, 'The gift message can only contain up to 450 characters.'),
      sendOn: z.string().refine(
        (dateStringified) => {
          if (!dateStringified) {
            return false;
          }

          const today = dayjs().startOf('day');
          const date = dayjs(dateStringified);
          return date.isAfter(today) || date.isSame(today, 'day');
        },
        { message: 'Select today or a future date' },
      ),
      sendPrintableCard: z.boolean().nullish(),
    }),
    /**
     * The subject of the project. (idk why this is called recipient).
     * This is only used when the project is a `BIOGRAPHY`.
     */
    recipient: createProjectCheckoutUserSchema(),
    /**
     * The name of the project.
     * This is only used when the project is a `BABYBOOK` or `FREE`.
     */
    name: z.string().min(1, 'Enter the project title'),
    /**
     * The subject of the project.
     * This is only used when the project is a `BABYBOOK`.
     */
    subject: z.object({
      firstName: z.string().nullish(),
    }),
    /**
     * The person ID of the recipient of the project.
     * This is only used when the project is a `FREE`.
     */
    recipientPersonId: z.string(),
    /**
     * The person ID of the owner of the subscription.
     * This is only used when the project is a `FREE`.
     */
    subscriptionOwnerPersonId: z.string(),
  });
}

export type ProjectCheckoutFormInput = z.input<ReturnType<typeof createProjectCheckoutFormSchema>>;
export type ProjectCheckoutFormOutput = z.output<ReturnType<typeof createProjectCheckoutFormSchema>>;
export type ProjectCheckoutForm = Form<ProjectCheckoutFormInput, ProjectCheckoutFormOutput>;

export function createProjectCheckoutForm(defaultValues?: DeepPartial<ProjectCheckoutFormInput>): ProjectCheckoutForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createProjectCheckoutFormSchema(),
  });
}
