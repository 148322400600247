import { styled } from '@linaria/react';

import { RMDialog } from '@/components/RMDialog';

export const Header = styled(RMDialog.Header)`
  padding-bottom: 0;
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardsGrid = styled.dl`
  display: flex;
  gap: var(--spacing-2xl);
  justify-content: center;

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-md);
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;

  body[data-mobile='true'] & {
    width: 100%;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 275px;
  object-fit: cover;
  object-position: top;

  body[data-mobile='true'] & {
    max-width: 320px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-2xs);
  width: 100%;
  padding-top: var(--spacing-2xl);

  body[data-mobile='false'] & {
    width: fit-content;
    margin: 0 auto;
  }
`;

export const LinkButtonWrapper = styled.a`
  display: contents;
`;
