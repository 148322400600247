import { RMInput } from '@/components/RMInput/RMInput';
import { RMSelect } from '@/components/RMSelect';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';
import { useInputValue } from '@/modules/form/input';
import { genitiveCase } from '@/utils/genitiveCase';

import { ProjectCheckoutForm } from '../../project-checkout.form.js';
import { ProjectCheckoutEbookCheckbox } from '../ProjectCheckoutEbookCheckbox/ProjectCheckoutEbookCheckbox.js';

import { Container, Field, FormGrid } from './BabybookProjectCheckoutBookForm.styles.js';

const QUANTITY_OPTIONS = Array.from({ length: 100 }).map((_, i) => ({
  label: `${i + 1} ${
    i === 0 ? '(included in purchase)' : `(${i} extra book${i > 1 ? 's' : ''}) - $${59 * i} (save $${10 * i})`
  }`,
  value: `${i + 1}`,
}));

export interface BabybookProjectCheckoutBookFormProps {
  form: ProjectCheckoutForm;
}

export function BabybookProjectCheckoutBookForm({ form }: BabybookProjectCheckoutBookFormProps) {
  const isMobile = useIsMobileViewport();
  const firstName = useInputValue(form, 'subject.firstName');

  return (
    <Container>
      <RMText as="h1" type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Name your book
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <FormGrid>
        <InputContainer form={form} path="name">
          {(props) => (
            <Field size="large">
              <RMInput
                id="book-name"
                label={'Book title (you can change this later)'}
                placeholder={`${genitiveCase(firstName || '')} First Year`}
                {...props}
              />
            </Field>
          )}
        </InputContainer>

        <InputController form={form} path="addons.books">
          {({ value, onChange, onBlur }) => (
            <Field size="large">
              <RMSelect
                id="quantity-select"
                label="Number of books"
                options={QUANTITY_OPTIONS}
                value={value != null ? String(value) : '1'}
                onChange={(newValue) => {
                  onChange(parseInt(newValue, 10));
                  onBlur();
                }}
              />
            </Field>
          )}
        </InputController>

        <InputController form={form} path="addons.ebook">
          {({ value, onChange }) => (
            <Field size="large">
              <ProjectCheckoutEbookCheckbox value={!!value} onChange={onChange} />
            </Field>
          )}
        </InputController>
      </FormGrid>
    </Container>
  );
}
