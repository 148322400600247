import { PropsWithChildren, ReactNode, useLayoutEffect, useRef } from 'react';
import { PersonName } from '@remento/types/person';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText.js';

import { ProjectCheckoutLoginLink } from '../ProjectCheckoutLoginLink/ProjectCheckoutLoginLink.js';
import { ProjectCheckoutLogoutLink } from '../ProjectCheckoutLogoutLink/ProjectCheckoutLogoutLink.js';

import {
  AuthButtonWrapper,
  Footer,
  Header,
  Main,
  NoteCard,
  ScrollArea,
  StyledProjectCheckoutMobileLayout,
  Wordmark,
} from './ExperimentalProjectCheckoutMobileLayout.styles.js';

export interface SignedInUser {
  name: PersonName | null;
  email: string;
}

export type ProjectCheckoutMobileLayout = PropsWithChildren<{
  Stepper: ReactNode;
  Summary: ReactNode;
  ContinueButton: ReactNode;
  currentStep: string;
  stickyFooter?: boolean;
  showSubscriptionNote?: boolean;
  user: SignedInUser | null;
  onSignIn: () => void;
  onSignOut: (redirect?: boolean) => Promise<void>;
}>;

export function ExperimentalProjectCheckoutMobileLayout({
  Stepper,
  Summary,
  ContinueButton,
  currentStep,
  stickyFooter = false,
  showSubscriptionNote = false,
  user,
  onSignIn,
  onSignOut,
  children,
}: ProjectCheckoutMobileLayout) {
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollAreaRef.current == null) {
      return;
    }
    scrollAreaRef.current.scrollTop = 0;
  }, [currentStep]);

  return (
    <StyledProjectCheckoutMobileLayout>
      <Header>
        <Wordmark destination="marketing" />
      </Header>
      {Summary}
      <ScrollArea ref={scrollAreaRef}>
        <Main>
          {Stepper}
          <RMSpacer spacing="md" direction="column" />
          {children}

          {!stickyFooter && <Footer data-sticky={stickyFooter}>{ContinueButton}</Footer>}

          {showSubscriptionNote && (
            <NoteCard role="region" aria-labelledby="checkout-layout-why-subscription">
              <RMText
                type="sans"
                size="xs"
                color="on-surface-primary"
                bold="medium"
                id="checkout-layout-why-subscription"
              >
                Why is Remento a subscription?
              </RMText>
              <RMText type="sans" size="xs" color="on-surface-primary">
                Remento is a subscription so that every person can work at their own pace. You only need an active
                subscription while recording—once your book is complete, simply cancel in just a few clicks. You'll
                still have lifetime access to all your recordings even after cancellation.{' '}
                <a href="https://help.remento.co/en/articles/10925084-why-is-remento-a-subscription" target="_blank">
                  <RMText type="sans" size="xs" color="on-surface-primary" underline>
                    Learn more
                  </RMText>
                </a>
                .
              </RMText>
            </NoteCard>
          )}

          <AuthButtonWrapper>
            {user != null ? (
              <ProjectCheckoutLogoutLink onSignOut={onSignOut} user={user} />
            ) : (
              <ProjectCheckoutLoginLink onClick={onSignIn} />
            )}
          </AuthButtonWrapper>
        </Main>
      </ScrollArea>
      {stickyFooter && <Footer data-sticky={stickyFooter}>{ContinueButton}</Footer>}
    </StyledProjectCheckoutMobileLayout>
  );
}
